























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import DesignData from '@/services/DesignData';

import Rates from '@/components/form/design/Rates.vue';
import CheckingRewards from '@/components/form/design/CheckingRewards.vue';
import Conditions from '@/components/form/design/Conditions.vue';
import Requirements from '@/components/form/design/Requirements.vue';

type ValidatedDesign = Vue & {
	hasError: () => boolean;
	isDirty: () => boolean;
	reset: () => void;
	touch: () => void;
};

@Component({
	components: {
		Rates,
		CheckingRewards,
		Conditions,
		Requirements,
	}
})
export default class RewardChecking extends Vue {
	@Prop({}) designData!: DesignData;

	checkingRewards: CheckingRewards | null = null;
	conditions: Conditions | null = null;
	requirements: Requirements | null = null;
	rates: Rates | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.checkingRewards = this.$refs.checkingRewards as CheckingRewards;
			this.conditions = this.$refs.conditions as Conditions;
			this.requirements = this.$refs.requirements as Requirements;
			this.rates = this.$refs.rates as Rates;
			this.isMounted = true;
		});
	}

	touch(): void {
		this.checkingRewards?.touch();
		this.conditions?.touch();
		this.requirements?.touch();
		this.rates?.touch();
	}
	reset(): void {
		this.checkingRewards?.reset();
		this.conditions?.reset();
		this.requirements?.reset();
		this.rates?.reset();
	}
	isDirty(): boolean {
		return this.isMounted && (
			this.checkingRewards?.isDirty() ||
			this.conditions?.isDirty() ||
			this.requirements?.isDirty() ||
			this.rates?.isDirty()
		) || false;
	}
	hasError(): boolean {
		return this.isMounted && (
			this.checkingRewards?.hasError() ||
			this.conditions?.hasError() ||
			this.requirements?.hasError() ||
			this.rates?.hasError()
		) || false;
	}
}


