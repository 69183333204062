export interface CashbackRates {
	bonusAPY: string | number | null; // decimal(10,2)
	bonusCap: string | number | null; // decimal(10,2)
	bonusLimit: string | number | null; // decimal(10,2)

	// unimplemented
	cashbackTierNum: number | null;
	secondTierCap: string | number | null;
	secondTierLimit: string | number | null;
	thirdTierCap: string | number | null;
	thirdTierLimit: string | number | null;
}

export const CashbackRatesFactory = function(): CashbackRates {
	return {
		bonusAPY: '0.00',
		bonusCap: '0.00',
		bonusLimit: '0.00',
		cashbackTierNum: null,
		secondTierCap: null,
		secondTierLimit: null,
		thirdTierCap: null,
		thirdTierLimit: null,
	} as CashbackRates;
};

