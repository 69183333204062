




































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { TunesDetailsFactory } from '@/services/design/TunesDetails';
import { decimal } from "vuelidate/lib/validators";
import validateDecimalPlaces from '@/mixins/validateDecimalPlaces';

type TunesDetailsValidation = Validation & {
	[key: string]: unknown;
	fakeField: Validation;
};

@Component({
	components: {}
})
export default class TunesDetails extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;
	decimalRegex = new RegExp('[.].+');

	get rewardType(): string {
		return this.designData.tunesDetails?.tunesDollars ? 'Dollars' : 'Downloads';
	}

	get perMonthDownloads() {
		if (this.designData.tunesDetails?.tunesDollars) {
			return parseFloat(this.designData.tunesDetails?.downloadsPerMonth || '0').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		}
		if (!this.designData.tunesDetails?.tunesDollars) {
			const value = this.designData.tunesDetails?.downloadsPerMonth || '0';
			return value.replace(this.decimalRegex, '');
		}
		return this.designData.tunesDetails?.downloadsPerMonth || '0';
	}

	set perMonthDownloads(value: string | undefined) {
		if (this.designData.tunesDetails && value) {
			this.designData.tunesDetails.downloadsPerMonth = value;
		}
	}

	get bonusDownloads() {
		if (this.designData.tunesDetails?.tunesDollars) {
			return parseFloat(this.designData.tunesDetails?.signUpBonusDownloads || '0').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		}
		if (!this.designData.tunesDetails?.tunesDollars) {
			const value = this.designData.tunesDetails?.signUpBonusDownloads || '0';
			return value.replace(this.decimalRegex, '');
		}
		return this.designData.tunesDetails?.signUpBonusDownloads;
	}

	set bonusDownloads(value: string | undefined) {
		if (this.designData.tunesDetails && value) {
			this.designData.tunesDetails.signUpBonusDownloads = value;
		}
	}

	get perMonthErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.$v.designData.tunesDetails?.downloadsPerMonth.$invalid) {
			return 'Only numbers are allowed.';
		}
		return null;
	}

	get bonusErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.$v.designData.tunesDetails?.signUpBonusDownloads.$invalid) {
			return 'Only numbers are allowed.';
		}
		return null;
	}

	validations(): RuleDecl {
		return {
			designData: {
				tunesDetails: {
					hasAmazonTunes: {},
					hasGooglePlayTunes: {},
					tunesDollars: {},
					downloadsPerMonth: {
						decimal,
						decimalPlaces: validateDecimalPlaces(2)
					},
					signUpBonusDownloads: {
						decimal,
						decimalPlaces: validateDecimalPlaces(2)
					}
				},
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.tunesDetails) {
			this.designData.tunesDetails = TunesDetailsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	get tunesDetailsValidation(): TunesDetailsValidation {
		return this.$v?.designData?.tunesDetails as TunesDetailsValidation;
	}

	touch() {
		this.tunesDetailsValidation.$touch();
	}

	reset() {
		this.tunesDetailsValidation.$reset();
	}

	isDirty(): boolean {
		return this.tunesDetailsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.tunesDetailsValidation.$anyError;
	}
}
