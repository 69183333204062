



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DesignData from '@/services/DesignData';
import SaverDetails from '@/components/form/design/SaverDetails.vue';
import Rates from '@/components/form/design/Rates.vue';
import SaverRewards from '@/components/form/design/SaverRewards.vue';

@Component({
	components: {
		SaverDetails,
		Rates,
		SaverRewards,
	}
})
export default class Saver extends Vue {
	@Prop({}) designData!: DesignData;

	rates: Rates | null = null;
	saverDetails: SaverDetails | null = null;
	saverRewards: SaverRewards | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.rates = this.$refs.rates as Rates;
			this.saverDetails = this.$refs.saverDetails as SaverDetails;
			this.saverRewards = this.$refs.saverRewards as SaverRewards;
			this.isMounted = true;
		});
	}

	touch(): void {
		this.rates?.touch();
		this.saverDetails?.touch();
		this.saverRewards?.touch();
	}
	reset(): void {
		this.rates?.reset();
		this.saverDetails?.reset();
		this.saverRewards?.reset();
	}
	isDirty(): boolean {
		return this.isMounted && (
			this.rates?.isDirty() ||
			this.saverDetails?.isDirty() ||
			this.saverRewards?.isDirty()
		) || false;
	}
	hasError(): boolean {
		return this.isMounted && (
			this.rates?.hasError() ||
			this.saverDetails?.hasError() ||
			this.saverRewards?.hasError()
		) || false;
	}
}


