






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ReductionAmounts as ReductionAmountsData } from '@/services/design/ReductionAmounts';
import { KReadOnly } from '@kasasa/fbase-components';

@Component({
	components: {
		KReadOnly
	}
})
export default class ReductionAmounts extends Vue {
	@Prop({default: false}) readonly reductionAmounts !: ReductionAmountsData;

	/**
	 * Emulates currency filter in functional form.
	 * This ensures the "unset amount" can be used.
	 */
	dollarFormat(x: string | number): string {
		return parseFloat((x).toString()).toLocaleString(undefined, {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	// what to display when the value is null / undefined / etc
	readonly unsetValue = '$0.00';

	// "E-Statement"
	get eStatementReductionAmount(): string {
		return this.reductionAmounts.reqEStatementReductionAmount ?
			this.dollarFormat(this.reductionAmounts.reqEStatementReductionAmount) :
			this.unsetValue;
	}

	// "Online Banking"
	get onlineBankingReductionAmount(): string {
		return this.reductionAmounts.reqOnlineBankingReductionAmount ?
			this.dollarFormat(this.reductionAmounts.reqOnlineBankingReductionAmount) :
			this.unsetValue;
	}

	// "Card Transaction"
	get transReductionAmount(): string {
		return this.reductionAmounts.reqTransReductionAmount ?
			this.dollarFormat(this.reductionAmounts.reqTransReductionAmount) :
			this.unsetValue;
	}
}

