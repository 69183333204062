
















































































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
// import { Validation } from 'vuelidate';
import { Features as IFeatures } from '@/services/design/Features';
import { RuleDecl } from 'vue/types/options';


@Component({
	components: {
		// placeholder
	}
})
export default class Features extends Vue implements DesignSection {
	@Prop({}) features!: IFeatures;
	@Prop({}) type!: number;

	validations(): RuleDecl {
		return {
			features: {
				autoQualifyCycles: {},
				freeChecking: {},
				freeBankATM: {},
				freeCard: {},
				freeCardName: {},
				freeOnlineBanking: {},
				freeOnlineBillPay: {},
			},
		} as RuleDecl;
	}

	isMounted = false;

	mounted() {
		this.isMounted = true;
	}

	readonly autoQualifyCycleOptions = [
		{ text: '', value: null },
		{ text: '0', value: 0 },
		{ text: '1', value: 1 },
	];

	// hirt

	touch() {
		this.$v.features.$touch();
	}

	reset() {
		this.$v.features.$reset();
	}

	isDirty(): boolean {
		return this.$v.features.$anyDirty;
	}

	hasError(): boolean {
		return this.$v.features.$anyError;
	}

	// no error messages?
}
