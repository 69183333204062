import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection, ExpandableFilter } from '@kasasa/fbase-components/lib'; 
import Product, { BatchProduct } from './Product';

interface ProductFilter {
	type?: string | null;
	types?: string | null;
}

export default class ProductService extends FBaseService {
	findAll(clientId: string, params: ProductFilter): Promise<AxiosResponse<ResponseEnvelopeCollection<Product>>> {
		return this.apiClient.get(`/api/kcms-product/client/${clientId}/product`, {
			params: params
		});
	}
	find(clientId: string, id: string, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelope<Product>>> {
		return this.apiClient.get(`/api/kcms-product/client/${clientId}/product/${id}`, {
			params: filter.getQuery()
		});
	}
	create(clientId: string, product: Product) {
		return this.apiClient.post(`/api/kcms-product/client/${clientId}/product`, product);
	}
	update(clientId: string, product: Product) {
		return this.apiClient.put(`/api/kcms-product/client/${clientId}/product/${product.id}`, product);
	}
	delete(clientId: string | number, id: string | number): Promise<AxiosResponse<ResponseEnvelope<unknown>>> {
		return this.apiClient.delete(`/api/kcms-product/client/${clientId}/product/${id}`);
	}
	batchCreate(clientId: string, products: BatchProduct[]) {
		return this.apiClient.post(`/api/kcms-product/client/${clientId}/product/batch`, products);
	}
}
