export interface ChoiceCheckingDetails {
	monthlyFeeName: string | null;
	monthlyFeeAmount: number | null;
	choiceCheckingModelType: string;
	minimumBalanceEnabled: boolean;
	minimumBalanceCycle: string;
	minimumBalanceAmount: number | null;
}

export const ChoiceCheckingDetailsFactory = function(): ChoiceCheckingDetails {
	return {
		monthlyFeeName: null,
		monthlyFeeAmount: null,
		choiceCheckingModelType: '',
		minimumBalanceEnabled: false,
		minimumBalanceCycle: '',
		minimumBalanceAmount: null,
	} as ChoiceCheckingDetails;
};
