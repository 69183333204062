






































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ProductListTable from '@/components/ProductListTable.vue';
import ProductService from '@/services/ProductService';
import Product from '@/services/Product';
import { getProductTypeName } from "@/services/ProductType";
import { KSpinner } from '@kasasa/fbase-components';
import { ListTableOptions, ListTableOptionsFactory } from '@/types/ListTableOptions';
import { ProductListTableFilters, ProductListTableFiltersFactory } from '@/types/ProductListTableFilters';
import { Route } from "vue-router";

type ProductWithHappyName = Product & {
	productTypeName: string | null;
};

@Component({
	components: {
		ProductListTable,
		KSpinner,
	}
})
export default class ListProductsPage extends Vue {
	@Prop({ default: '' }) readonly clientId!: string;

	status: 'loading' | 'loaded' | 'empty' = 'loading';

	productSvc = new ProductService(this.$store);
	products: Array<Product | ProductWithHappyName> = [];

	tableFilters: ProductListTableFilters = this.$store.getters['tableFilters'];
	tableOptions: ListTableOptions = ListTableOptionsFactory();

	@Watch('$route', { immediate: true, deep: true })
	loadProducts(prev: Route, current: Route) {
		this.status = 'loading';
		this.products = [];
		this.tableOptions = ListTableOptionsFactory();
		if ((prev && current) && prev?.params.clientId !== current?.params.clientId) {
			this.tableFilters = ProductListTableFiltersFactory();
			this.$store.commit('SET_TABLE_FILTERS', this.tableOptions);
		}

		this.productSvc.findAll(this.$route.params.clientId, {})
			.then((resp) => {
				this.products = resp.data.data.map((product: Product): ProductWithHappyName => {
					return {
						...product,
						productTypeName: product.fkProductType ? getProductTypeName(product.fkProductType) : '',
					} as ProductWithHappyName;
				});
				this.status = (this.products.length ? 'loaded' : 'empty');
			})
			.catch(() => {
				// no errors besides 401, 403 and 500 to listen for.
			});
	}

}
