

























































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import { url } from 'vuelidate/lib/validators';
import { WholeDollarValidator } from '@/components/form/validations/Currency';
import DesignData from '@/services/DesignData';
import { BaseFactory, Base as IBase } from '@/services/design/Base';
import TextToNumber from '@/mixins/TextToNumber';
import { KRadioGroup, KText } from '@kasasa/fbase-components';

type BaseValidation = Validation & {
	[key: string]: unknown;
	isKasasa: Validation;
	externalAppURL: Validation;
	insuredBy: Validation;
	initialDepositMin: Validation;
	initialDepositMax: Validation;
	initialCardDepositMin: Validation;
	initialCardDepositMax: Validation;
};

@Component({
	components: {
		KRadioGroup, KText
	}
})
export default class DesignBase extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData & { base: IBase };

	readonly insuredByItems = [
		{ text: 'ASI', value: 'asi' },
		{ text: 'FDIC', value: 'fdic' },
		{ text: 'NCUA', value: 'ncua' },
		{ text: 'None', value: 'none' },
	];

	readonly isKasasaItems = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];

	initialDepositInputToModel(value: string | number | null): string | number | null {
		return TextToNumber(value);
	}

	validations(): RuleDecl {
		return {
			designData: {
				base: {
					isKasasa: {},
					externalAppURL: { url },
					insuredBy: {},
					initialDepositMin: { WholeDollarValidator },
					initialDepositMax: { WholeDollarValidator },
					initialCardDepositMin: { WholeDollarValidator },
					initialCardDepositMax: { WholeDollarValidator },
				},
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.base) {
			this.designData.base = BaseFactory();
		}
		this.setDesignData(this.designData);
	}

	isMounted = false;

	mounted() {
		this.isMounted = true;
	}

	get baseValidation(): BaseValidation {
		return this.$v?.designData?.base as unknown as BaseValidation;
	}

	touch() {
		this.baseValidation.$touch();
	}

	reset() {
		this.baseValidation.$reset();
	}

	isDirty(): boolean {
		return this.baseValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.baseValidation.$anyError;
	}

	readonly integerDollarError = 'Only whole dollar amounts are allowed';

	get isKasasaErrorMessage(): string | null {
		return null; // no validation
	}

	get externalAppURLErrorMessage(): string | null {
		return (
			this.isMounted &&
			(this.baseValidation.externalAppURL.$invalid ? 'Please provide a properly formatted URL' : null)
		) || null;
	}

	get insuredByErrorMessage(): string | null {
		return null; // no validation
	}

	get initialDepositMinErrorMessage(): string | null {
		return (
			this.isMounted &&
			(this.baseValidation.initialDepositMin.$invalid ? this.integerDollarError : null)
		) || null;
	}

	get initialDepositMaxErrorMessage(): string | null {
		return (
			this.isMounted &&
			(this.baseValidation.initialDepositMax.$invalid ? this.integerDollarError : null)
		) || null;
	}

	get initialCardDepositMinErrorMessage(): string | null {
		return (
			this.isMounted &&
			(this.baseValidation.initialCardDepositMin.$invalid ? this.integerDollarError : null)
		) || null;
	}

	get initialCardDepositMaxErrorMessage(): string | null {
		return (
			this.isMounted &&
			(this.baseValidation.initialCardDepositMax.$invalid ? this.integerDollarError : null)
		) || null;
	}


}
