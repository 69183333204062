

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
	components: {
		draggable,
	}
})
export default class DclGrid extends Vue {
	@Prop({ default: () => false }) disabled!: boolean;
}

