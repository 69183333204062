


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DesignData from '@/services/DesignData';
import { Requirements as IRequirements } from '@/services/design/Requirements';

@Component({
	components: {}
})

export default class EStatement extends Vue {
	@Prop({}) readonly design !: DesignData & { requirements: IRequirements };

	get reqEStatement() {
		return this.design.requirements?.eStatement?.reqEStatement ? 'Yes' : 'No';
	}

	get reqEStatementView() {
		return this.design.requirements?.eStatement?.reqEStatementView ? 'Yes' : 'No';
	}
}
