


























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Base as IBase } from '@/services/design/Base';
import { BoolYesNoFilter } from "@/filters/BoolYesNo";
import { KReadOnly } from '@kasasa/fbase-components';

@Component({
	components: {
		KReadOnly
	}
})

// NAMING THE CLASS "DesignBase" TO AVOID CONFLICT WITH HTML TAG "BASE"
export default class DesignBase extends Vue {
	@Prop({default: false}) readonly baseData !: IBase;

	formatYesNo(value: boolean) {
		return BoolYesNoFilter(value);
	}

	dollarFormat(x: number) {
		return '$' + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	get insuredBy() {
		return !this.baseData.insuredBy || this.baseData.insuredBy === 'none' ? 'None' : this.baseData.insuredBy.toUpperCase();
	}

	get initialDepositMin() {
		return this.baseData.initialDepositMin || this.baseData.initialDepositMin == 0 ?
			this.dollarFormat(Number(this.baseData.initialDepositMin)) :
			null;
	}
	get initialDepositMax() {
		return this.baseData.initialDepositMax || this.baseData.initialDepositMax == 0 ?
			this.dollarFormat(Number(this.baseData.initialDepositMax)) :
			null;
	}
	get initialCardDepositMin() {
		return this.baseData.initialCardDepositMin || this.baseData.initialCardDepositMin == 0 ?
			this.dollarFormat(Number(this.baseData.initialCardDepositMin)) :
			null;
	}
	get initialCardDepositMax() {
		return this.baseData.initialCardDepositMax || this.baseData.initialCardDepositMax == 0 ?
			this.dollarFormat(Number(this.baseData.initialCardDepositMax)) :
			null;
	}
}

