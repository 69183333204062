







































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import { required } from 'vuelidate/lib/validators';

type OtherRequirementsValidation = Validation & {
	[key: string]: unknown;
	others: Validation;
};

@Component({
	components: {}
})
export default class OtherRequirements extends Vue implements DesignSection {
	@Prop({}) others!: Array<string>;

	isMounted = false;

	validations(): RuleDecl {
		return {
			others: {
				$each: {
					required
				}
			}
		} as RuleDecl;
	}

	mounted() {
		this.isMounted = true;
	}

	get otherRequirementsValidation(): OtherRequirementsValidation {
		return this.$v.others as OtherRequirementsValidation;
	}

	get othersErrorMessage(): string | null {
		return this.isMounted ? (
			this.$v?.others?.$error ? 'Description is required.' : null
		) : null;
	}

	touch() {
		this.otherRequirementsValidation.$touch();
	}

	reset() {
		this.otherRequirementsValidation.$reset();
	}

	isDirty(): boolean {
		return this.otherRequirementsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.otherRequirementsValidation.$anyError;
	}
	
	add() {
		this.others.push("");
	}

	remove(index: number) {
		this.others.splice(index, 1);
	}
}
