export interface TunesDetails {
	[key: string]: unknown;
	hasAmazonTunes: boolean;
	hasGooglePlayTunes: boolean;
	tunesDollars: boolean;
	downloadsPerMonth: string;
	signUpBonusDownloads: string;
}

export const TunesDetailsFactory = function(): TunesDetails {
	return {
		hasAmazonTunes: true,
		hasGooglePlayTunes: true,
		tunesDollars: false,
		downloadsPerMonth: '0',
		signUpBonusDownloads: '0',
	} as TunesDetails;
};