import Vue from 'vue';

export const addPercentSuffix = Vue.filter('percent', (value: number) => {
	return value + '%';
});

export const PercentFilter = Vue.filter('percent', (value: string) => {
	if (value) {
		return addPercentSuffix(value);
	}
});