




























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ChoiceCheckingDetails } from '@/services/design/ChoiceCheckingDetails';

@Component({
	components: {}
})

export default class ChoiceChecking extends Vue {
	@Prop({default: false}) readonly choiceCheckingDetails !: ChoiceCheckingDetails;

	dollarFormat(x: number) {
		return '$' + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	get choiceCheckingModelType() {
		return this.choiceCheckingDetails.choiceCheckingModelType.charAt(0).toUpperCase() + this.choiceCheckingDetails.choiceCheckingModelType.slice(1);
	}

	get monthlyFeeAmount() {
		return this.choiceCheckingDetails.monthlyFeeAmount !== null ? this.dollarFormat(Number(this.choiceCheckingDetails.monthlyFeeAmount)) : '[Not Set]';
	}

	get monthlyFeeName() {
		return this.choiceCheckingDetails.monthlyFeeName || '[Not Set]';
	}

	get minimumBalanceEnabled() {
		return this.choiceCheckingDetails.minimumBalanceEnabled ? 'Yes' : 'No';
	}

	get minimumBalanceAmount() {
		return this.choiceCheckingDetails.minimumBalanceAmount !== null ? this.dollarFormat(Number(this.choiceCheckingDetails.minimumBalanceAmount)) : '[Not Set]';
	}

	get minimumBalanceCycle() {
		return this.choiceCheckingDetails.minimumBalanceCycle.charAt(0).toUpperCase() + this.choiceCheckingDetails.minimumBalanceCycle.slice(1);
	}
}
