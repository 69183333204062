










































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import DesignData from '@/services/DesignData';
import { CashbackRatesFactory } from '@/services/design/CashbackRates';

import { CurrencyValidator } from '@/components/form/validations/Currency';
import { RatesValidator } from '@/components/form/validations/Rates';
import { RuleDecl } from 'vue/types/options';
import { KText } from '@kasasa/fbase-components';

type CashbackRatesValidation = Validation & {
	[key: string]: unknown;
	bonusAPY: Validation;
	bonusCap: Validation;
	bonusLimit: Validation;
};

@Component({
	components: {
		KText
	}
})
export default class CashbackRates extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	validations(): RuleDecl {
		return {
			designData: {
				cashbackRates: {
					bonusAPY: {
						RatesValidator
					},
					bonusCap: {
						CurrencyValidator
					},
					bonusLimit: {
						CurrencyValidator
					}
				},
			}
		} as RuleDecl;
	}

	isMounted = false;

	created() {
		if (!this.designData.cashbackRates) {
			this.designData.cashbackRates = CashbackRatesFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	get cashbackRatesValidation(): CashbackRatesValidation {
		return (this.$v?.designData?.cashbackRates as CashbackRatesValidation);
	}

	touch() {
		this.cashbackRatesValidation.$touch();
	}

	reset() {
		this.cashbackRatesValidation.$reset();
	}

	isDirty(): boolean {
		return this.cashbackRatesValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.cashbackRatesValidation.$anyError;
	}

	get bonusAPYErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.cashbackRatesValidation.bonusAPY?.$invalid) {
			return 'Only percentage amounts are allowed';
		}
		return null;
	}

	get bonusCapErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.cashbackRatesValidation.bonusCap?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

	get bonusLimitErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.cashbackRatesValidation.bonusLimit?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

}
