





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CashbackRates as CashbackRatesData } from '@/services/design/CashbackRates';
import { KReadOnly } from '@kasasa/fbase-components';

import { PercentFilter } from "@/filters/Percent";
import { CurrencyFilter } from "@/filters/Currency";

@Component({
	components: {
		KReadOnly,
	},
	filters: {
		PercentFilter,
		CurrencyFilter,
	}
})
export default class CashbackRates extends Vue {
	@Prop({default: false}) readonly cashbackRates !: CashbackRatesData;
}

