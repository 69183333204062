























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DesignData from '@/services/DesignData';
import CheckingRewards from '@/components/form/design/CheckingRewards.vue';
import Conditions from '@/components/form/design/Conditions.vue';
import Requirements from '@/components/form/design/Requirements.vue';
import TunesDetails from '@/components/form/design/TunesDetails.vue';

type DesignRef = Vue & {
	touch: () => void;
	reset: () => void;
	isDirty: () => boolean;
	hasError: () => boolean;
};

@Component({
	components: {
		CheckingRewards,
		Conditions,
		Requirements,
		TunesDetails,
	}
})
export default class Tunes extends Vue {
	@Prop({}) designData!: DesignData;
	isMounted = false;

	mounted() {
		this.isMounted = true;
	}

	getTunesDetailsRef() {
		return (
			this.$refs.tunesDetailsRef
		) as DesignRef;
	}

	getCheckingRewardsRef() {
		return (
			this.$refs.checkingRewardsRef
		) as DesignRef;
	}

	getConditionsRef() {
		return (
			this.$refs.conditionsRef
		) as DesignRef;
	}

	getRequirementsRef() {
		return (
			this.$refs.requirementsRef
		) as DesignRef;
	}

	touch(): void {
		this.getTunesDetailsRef().touch();
		this.getCheckingRewardsRef().touch();
		this.getConditionsRef().touch();
		this.getRequirementsRef().touch();
	}

	reset(): void {
		this.getTunesDetailsRef().reset();
		this.getCheckingRewardsRef().reset();
		this.getConditionsRef().reset();
		this.getRequirementsRef().reset();
	}

	isDirty(): boolean {
		if (!this.isMounted) {
			return false;
		}
		return this.getTunesDetailsRef().isDirty() ||
			this.getCheckingRewardsRef().isDirty() ||
			this.getConditionsRef().isDirty() ||
			this.getRequirementsRef().isDirty();
	}

	hasError(): boolean {
		if (!this.isMounted) {
			return false;
		}
		return this.getTunesDetailsRef().hasError() ||
			this.getCheckingRewardsRef().hasError() ||
			this.getConditionsRef().hasError() ||
			this.getRequirementsRef().hasError();
	}
}


