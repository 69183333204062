








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ProductListFilterStatus extends Vue {
	@Prop({}) enabled!: number;

	get selected(): number {
		return this.enabled;
	}
	set selected(value: number) {
		this.$emit('update', value);
	}

	statusOptions: Array<object> = [
		{'value': 2, 'text': 'All'},
		{'value': 1, 'text': 'Active'},
		{'value': 0, 'text': 'Inactive'}
	];

}
