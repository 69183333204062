export interface Conditions {
	conditionDirectDeposit: boolean;
	conditionEStatement: boolean;
	conditionOnlineBanking: boolean;
	conditionValidEmail: boolean;
}
export const ConditionsFactory = function(): Conditions {
	return {
		conditionDirectDeposit: true,
		conditionEStatement: true,
		conditionOnlineBanking: true,
		conditionValidEmail: true,
	} as Conditions;
};