
















































































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';

import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import { AutomaticTransfersFactory ,AutoTransferMap, getAutoTransferType, AutomaticTransfers as IAutomaticTransfers } from '@/services/design/AutomaticTransfers';
import { integer } from 'vuelidate/lib/validators';
import { CurrencyValidator } from '@/components/form/validations/Currency';
import { KText } from '@kasasa/fbase-components';

type AutomaticTransfersValidation = Validation & {
	[key: string]: unknown;
	reqACHNum: Validation;
	reqAutoTransferType: Validation;
	reqACHMinAmount: Validation;
	reqBillPayNum: Validation;
	reqDirectDepositNum: Validation;
	reqDirectDepositMinAmount: Validation;
	reqACHOrBillPayNum: Validation;
	reqACHOrDirectDepositNum: Validation;
	reqBillPayOrDirectDepositNum: Validation;
	reqACHOrBillPayOrDirectDepositNum: Validation;
};

@Component({
	components: {
		KText
	}
})
export default class AutomaticTransfers extends Vue implements DesignSection {
	@Prop({}) automaticTransfers!: IAutomaticTransfers;

	isMounted = false;
	AutoTransferMap = AutoTransferMap;
	AutomaticTransfersFactory = AutomaticTransfersFactory();

	validations(): RuleDecl {
		// TODO?: Future ticket, this vue file uses HTML display logic for displaying fields. If we want the correct "required" validation we need logic for whether the input is displayed or not, 
		// or go hybrid and use :rules for those fields
		return {
			automaticTransfers: {
				reqACHNum: {
					integer
				},
				reqAutoTransferType: {},
				reqACHMinAmount: {
					CurrencyValidator
				},
				reqBillPayNum: {
					integer
				},
				reqDirectDepositNum: {
					integer
				},
				reqDirectDepositMinAmount: {
					CurrencyValidator
				},
				reqACHOrBillPayNum: {
					integer
				},
				reqACHOrDirectDepositNum: {
					integer
				},
				reqBillPayOrDirectDepositNum: {
					integer
				},
				reqACHOrBillPayOrDirectDepositNum: {
					integer
				},
			}
		} as RuleDecl;
	}

	mounted() {
		this.isMounted = true;
	}

	get automaticTransfersValidation(): AutomaticTransfersValidation {
		return this.$v.automaticTransfers as AutomaticTransfersValidation;
	}

	touch() {
		this.automaticTransfersValidation.$touch();
	}

	reset() {
		this.automaticTransfersValidation.$reset();
	}

	isDirty(): boolean {
		return this.automaticTransfersValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.automaticTransfersValidation.$anyError;
	}

	display(type: string): boolean {
		return getAutoTransferType(this.automaticTransfers?.reqAutoTransferType)?.display.includes(type) || false;
	}

	typeChange() {
		this.$v?.automaticTransfers?.reqAutoTransferType?.$touch();

		// Reset all fields when type is changed
		this.automaticTransfers.reqACHNum = null;
		this.automaticTransfers.reqACHMinAmount = null;
		this.automaticTransfers.reqBillPayNum = null;
		this.automaticTransfers.reqDirectDepositNum = null;
		this.automaticTransfers.reqDirectDepositMinAmount = null;
		this.automaticTransfers.reqACHOrBillPayNum = null;
		this.automaticTransfers.reqACHOrDirectDepositNum = null;
		this.automaticTransfers.reqBillPayOrDirectDepositNum = null;
		this.automaticTransfers.reqACHOrBillPayOrDirectDepositNum = null;
	}

	get reqACHNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqACHNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqACHMinAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqACHMinAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

	get reqBillPayNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqBillPayNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqDirectDepositNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqDirectDepositNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqDirectDepositMinAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqDirectDepositMinAmount?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqACHOrBillPayNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqACHOrBillPayNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqACHOrDirectDepositNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqACHOrDirectDepositNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqBillPayOrDirectDepositNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqBillPayOrDirectDepositNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

	get reqACHOrBillPayOrDirectDepositNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.automaticTransfersValidation.reqACHOrBillPayOrDirectDepositNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}

}
