


























import Vue from 'vue';
import { namespace } from 'vuex-class';

import { KPortalHeader, KAppToolbar, KAppToolbarBtn, KNoticeContainer } from '@kasasa/fbase-components';
import Component from 'vue-class-component';
import { AuthGroupManager } from '@kasasa/fbase-components/lib';

const auth = namespace('auth');

@Component({

	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn
	},

})
export default class App extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;
	@auth.State('userStatus') userStatus!: string;
	
	navigateToAddNew(): void {
		this.$router.push({ name: 'AddProduct', params: { clientId: this.$route.params.clientId }});
	}

	navigateToBatchAdd(): void {
		this.$router.push({ name: 'BatchCreate', params: { clientId: this.$route.params.clientId }});
	}

	get canWrite() {
		return this.authManager.canWrite('products');
	}
	get	canMultiAdd() {
		return this.authManager.isRoot() || this.authManager.isInternal();
	}
}
