
import ListTableFilters from '@/types/ListTableFilters';

interface ProductListTableFilters extends ListTableFilters {
	productTypes: Array<number>;
	enabled: number;
}

const ProductListTableFiltersFactory = () => {
	return {
		search: '',
		useFilter: false,
		productTypes: [],
		enabled: 2,
	} as ProductListTableFilters;
};

export default ProductListTableFilters;
export {
	ProductListTableFilters,
	ProductListTableFiltersFactory,
};

