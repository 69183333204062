































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Product from '@/services/Product';
import { DataTableHeader } from 'vuetify';
import ProductListFilter from '@/components/ProductListFilter/ProductListFilter.vue';

import ListTableOptions from '@/types/ListTableOptions';
import ProductListTableFilters from '@/types/ProductListTableFilters';

type ProductWithHappyName = Product & {
	productTypeName: string | null;
};

@Component({
	components: {
		ProductListFilter
	}
})
export default class ProductListTable extends Vue {
	@Prop({ default: () => [] }) readonly products!: Array<ProductWithHappyName>;
	@Prop({}) tableOptions!: ListTableOptions;
	@Prop({}) tableFilters!: ProductListTableFilters;

	get filteredList(): Array<ProductWithHappyName> {
		return this.products.filter((product: ProductWithHappyName) => {
			if (this.tableFilters.search && !this.filterSearch(product, this.tableFilters.search)) {
				return false;
			}

			if (this.tableFilters.productTypes.length && !this.filterProductType(product, this.tableFilters.productTypes)) {
				return false;
			}

			if (!this.filterProductStatus(product, this.tableFilters.enabled)) {
				return false;
			}

			return true;
		});
	}

	get useFilter() {
		return this.$store.getters['tableFilters'].useFilter;
	}
	
	searchHighlight(value: string|number): string | number  {
		if (!this.tableFilters.search?.length || value === null) {
			return value;
		}
		return value.toString().replace(
			RegExp(this.tableFilters.search, 'ig'),
			(matchText) => '<strong style="color: #000;">' + matchText + '</strong>'
		);
	}

	handleFilterChange() {
		this.$store.commit('SET_TABLE_FILTERS', this.tableFilters);
		const filterRef: ProductListFilter = this.$refs.productListFilterRef as ProductListFilter;
		if (this.tableFilters.useFilter === false) {
			filterRef.reset();
		}
	}

	filterSearch(product: ProductWithHappyName, filterValue: string): boolean {
		return product.name ? (
			product.name.toLowerCase().indexOf(filterValue.toLowerCase()) != -1
		) : false;
	}

	filterProductType(product: ProductWithHappyName, filterValue: Array<number>): boolean {
		return product.fkProductType ? filterValue.includes(product.fkProductType) : false;
	}

	filterProductStatus(product: ProductWithHappyName, filterValue: number): boolean {
		if (filterValue === product.enabled || filterValue === 2) {
			return true;
		}
		return false;
	}

	headers: DataTableHeader[] = [
		{ text: 'Product Name', value: 'name', width: '33%', align: 'start' },
		{ text: 'Type', value: 'productTypeName', width: '25%', align: 'start' },
		{ text: 'Product Status', value: 'enabled', width: '42%', align: 'start' }
	];

}
