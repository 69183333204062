




































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import CheckingRewards from '@/components/form/design/CheckingRewards.vue';
import Conditions from '@/components/form/design/Conditions.vue';
import Requirements from '@/components/form/design/Requirements.vue';
import CashbackRates from '@/components/form/design/CashbackRates.vue';

import DesignData from '@/services/DesignData';

@Component({
	components: {
		CheckingRewards,
		Conditions,
		Requirements,
		CashbackRates,
	}
})
export default class Cashback extends Vue {
	@Prop({}) designData!: DesignData;

	checkingRewards: CheckingRewards | null = null;
	conditions: Conditions | null = null;
	requirements: Requirements | null = null;
	cashbackRates: CashbackRates | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.checkingRewards = this.$refs.checkingRewards as CheckingRewards;
			this.conditions = this.$refs.conditions as Conditions;
			this.requirements = this.$refs.requirements as Requirements;
			this.cashbackRates = this.$refs.cashbackRates as CashbackRates;
			this.isMounted = true;
		});
	}

	touch(): void {
		this.checkingRewards?.touch();
		this.conditions?.touch();
		this.requirements?.touch();
		this.cashbackRates?.touch();
	}
	reset(): void {
		this.checkingRewards?.reset();
		this.conditions?.reset();
		this.requirements?.reset();
		this.cashbackRates?.reset();
	}
	isDirty(): boolean {
		return this.isMounted && (
			this.checkingRewards?.isDirty() ||
			this.conditions?.isDirty() ||
			this.requirements?.isDirty() ||
			this.cashbackRates?.isDirty()
		) || false;
	}
	hasError(): boolean {
		return this.isMounted && (
			this.checkingRewards?.hasError() ||
			this.conditions?.hasError() ||
			this.requirements?.hasError() ||
			this.cashbackRates?.hasError()
		) || false;
	}
}


