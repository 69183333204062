






















































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { ConditionsFactory } from '@/services/design/Conditions';
import { KRadioGroup } from '@kasasa/fbase-components';

type ConditionsValidation = Validation & {
	[key: string]: unknown;
	conditionDirectDeposit: Validation;
	conditionEStatement: Validation;
	conditionOnlineBanking: Validation;
	conditionValidEmail: Validation;
};

@Component({
	components: { KRadioGroup }
})
export default class Conditions extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	readonly YesNoItems = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];

	isMounted = false;

	validations(): RuleDecl {
		return {
			designData: {
				conditions: {
					conditionDirectDeposit: {},
					conditionEStatement: {},
					conditionOnlineBanking: {},
					conditionValidEmail: {}
				},
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.conditions) {
			this.designData.conditions = ConditionsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	get conditionsValidation(): ConditionsValidation {
		return this.$v?.designData?.conditions as ConditionsValidation;
	}

	touch() {
		this.conditionsValidation.$touch();
	}

	reset() {
		this.conditionsValidation.$reset();
	}

	isDirty(): boolean {
		return this.conditionsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.conditionsValidation.$anyError;
	}

}
