











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import GeneralInfo from './GeneralInfo.vue';
import ProductDetails from './ProductDetails.vue';
import ProductDesign from './ProductDesign.vue';
import Product from '@/services/Product';

@Component({
	components: {
		GeneralInfo,
		ProductDetails,
		ProductDesign
	}
})
export default class ProductTabs extends Vue {
	@Prop({ default: false }) readonly product!: Product;
	@Prop({ default: false }) readonly showDesignTab!: boolean;
	tab = null;
}
