












































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { ChoiceCheckingDetailsFactory } from '@/services/design/ChoiceCheckingDetails';
import { CurrencyValidator } from '@/components/form/validations/Currency';

type ChoiceCheckingDetailsValidation = Validation & {
	[key: string]: unknown;
	monthlyFeeName: Validation;
	monthlyFeeAmount: Validation;
	choiceCheckingModelType: Validation;
	minimumBalanceEnabled: Validation;
	minimumBalanceCycle: Validation;
	minimumBalanceAmount: Validation;
};

@Component({
	components: {}
})
export default class ChoiceCheckingDetails extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;

	validations(): RuleDecl {
		return {
			designData: {
				choiceCheckingDetails: {
					monthlyFeeName: {},
					monthlyFeeAmount: {
						CurrencyValidator
					},
					choiceCheckingModelType: {},
					minimumBalanceEnabled: {},
					minimumBalanceCycle: {},
					minimumBalanceAmount: {
						CurrencyValidator
					}
				}
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.choiceCheckingDetails) {
			this.designData.choiceCheckingDetails = ChoiceCheckingDetailsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	get choiceCheckingDetailsValidation(): ChoiceCheckingDetailsValidation {
		return this.$v?.designData?.choiceCheckingDetails as ChoiceCheckingDetailsValidation;
	}

	touch() {
		this.choiceCheckingDetailsValidation.$touch();
	}

	reset() {
		this.choiceCheckingDetailsValidation.$reset();
	}

	isDirty(): boolean {
		return this.choiceCheckingDetailsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.choiceCheckingDetailsValidation.$anyError;
	}

	get monthlyFeeAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.choiceCheckingDetailsValidation.monthlyFeeAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

	get minimumBalanceAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.choiceCheckingDetailsValidation.minimumBalanceAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}
}
