import { Base, BaseFactory } from '@/services/design/Base';
import { CashbackRates, CashbackRatesFactory } from '@/services/design/CashbackRates';
import { CheckingRewards } from '@/services/design/CheckingRewards';
import { ChoiceCheckingDetails } from '@/services/design/ChoiceCheckingDetails';
import { Conditions, ConditionsFactory } from '@/services/design/Conditions';
import { InControlDetails, InControlDetailsFactory } from '@/services/design/InControlDetails';
import { ReductionAmounts, ReductionAmountsFactory } from '@/services/design/ReductionAmounts';
import { Requirements, RequirementsFactory } from '@/services/design/Requirements';
import { SaverDetails } from '@/services/design/SaverDetails';
import { SaverRewards } from '@/services/design/SaverRewards';
import { TunesDetails } from '@/services/design/TunesDetails';
import { Rates, RatesFactory } from '@/services/design/Rates';

interface DesignData {
	type: number;
	base: Base | null;
	cashbackRates: CashbackRates | null;
	checkingRewards: CheckingRewards | null;
	choiceCheckingDetails: ChoiceCheckingDetails | null;
	conditions: Conditions | null;
	inControlDetails: InControlDetails | null;
	rates: Rates | null;
	reductionAmounts: ReductionAmounts | null;
	requirements: Requirements | null;
	saverDetails: SaverDetails | null;
	saverRewards: SaverRewards | null;
	tunesDetails: TunesDetails | null;
}


const defaultDesignData: DesignData = {
	type: 1,
	base: null,
	cashbackRates: null,
	checkingRewards: null,
	choiceCheckingDetails: null,
	conditions: null,
	inControlDetails: null,
	rates: null,
	reductionAmounts: null,
	requirements: null,
	saverDetails: null,
	saverRewards: null,
	tunesDetails: null,
};

/**
 * Use factories for sub-items when available
 * This allows vue modelling to track these values
 */
const DesignDataFactory = function(fkProductType: number | null): DesignData {
	const designData = Object.assign({}, defaultDesignData) as DesignData;
	
	/* Reference:
	 * 1 - None
	 * 2 - Savings
	 * 3 - Loans
	 * 4 - Investments
	 * 5 - Lending
	 * 6 - Other
	 * 7 - Checking
	 * 8 - RewardChecking
	 * 9 - Saver
	 * 10 - Tunes
	 * 12 - ChoiceChecking
	 * 13 - Cashback
	 * 15 - InControlChecking
	 */

	if (!fkProductType) {
		return designData;
	}
	
	designData.type = fkProductType;

	// base on all
	designData.base = BaseFactory();

	// reduction amounts: choice checking only
	if ([12].includes(fkProductType)) {
		designData.reductionAmounts = ReductionAmountsFactory();
	}

	// conditions: checking, tunes, cashback
	if ([8, 10, 13].includes(fkProductType)) {
		designData.conditions = ConditionsFactory();
	}

	// rates: reward checking, saver
	if ([8, 9].includes(fkProductType)) {
		designData.rates = RatesFactory();
	}

	// cashback rates: cashback
	if ([13].includes(fkProductType)) {
		designData.cashbackRates = CashbackRatesFactory();
	}

	// in-control details: in-control checking
	if ([15].includes(fkProductType)) {
		designData.inControlDetails = InControlDetailsFactory();
	}

	// requirements: reward checking, tunes, choice checking, cashback
	if([8, 10, 12, 13].includes(fkProductType)) {
		designData.requirements = RequirementsFactory();
	}

	return designData;
};


export default DesignData;
export {
	defaultDesignData,
	DesignData,
	DesignDataFactory,
};
