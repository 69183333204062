
interface ListTableOptions {
	page: number;
	itemsPerPage: number;
	sortBy: Array<string>;
	sortDesc: Array<boolean>;
	groupBy?: Array<string>;
	groupDesc?: Array<boolean>;
	multiSort?: boolean;
	mustSort?: boolean;
}

const ListTableOptionsFactory = () => {
	return {
		page: 1,
		itemsPerPage: 10,
		sortBy: ['name'],
		sortDesc: [false],
		multiSort: false,
		mustSort: true,
	} as ListTableOptions;
};

export default ListTableOptions;
export {
	ListTableOptions,
	ListTableOptionsFactory,
};
