






















































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DesignData from '@/services/DesignData';
import { getAutoTransferType } from '@/services/design/AutomaticTransfers';
import { Requirements as IRequirements } from '@/services/design/Requirements';
import Transactions from './Transactions.vue';
import EStatement from './EStatement.vue';

@Component({
	components: {
		Transactions,
		EStatement
	}
})
export default class Requirements extends Vue {
	@Prop({}) readonly design !: DesignData & { requirements: IRequirements };
	
	showTransactions = false;
	showAutoTransfer = false;
	showEStatement = false;
	showOnlineBanking = false;

	dollarFormat(x: number) {
		return '$' + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	// Automatic Transfers

	display(type: string): boolean {
		if (!this.design.requirements) {
			return false;
		}
		return getAutoTransferType(this.design.requirements.automaticTransfers?.reqAutoTransferType)?.display.includes(type) || false;
	}

	get reqAutoTransferType() {
		return getAutoTransferType(this.design.requirements.automaticTransfers?.reqAutoTransferType)?.label;
	}

	get reqACHNum() {
		return this.design.requirements.automaticTransfers.reqACHNum || '[Not Set]';
	}

	get reqACHMinAmount() {
		return this.design.requirements.automaticTransfers?.reqACHMinAmount ||
			this.design.requirements.automaticTransfers?.reqACHMinAmount == 0 ?
			this.dollarFormat(Number(this.design.requirements.automaticTransfers?.reqACHMinAmount)) :
			'[Not Set]';
	}

	get reqBillPayNum() {
		return this.design.requirements.automaticTransfers?.reqBillPayNum || '[Not Set]';
	}

	get reqDirectDepositNum() {
		return this.design.requirements.automaticTransfers?.reqDirectDepositNum || '[Not Set]';
	}

	get reqDirectDepositMinAmount() {
		return this.design.requirements.automaticTransfers?.reqDirectDepositMinAmount ||
			this.design.requirements.automaticTransfers?.reqDirectDepositMinAmount == 0 ?
			this.dollarFormat(Number(this.design.requirements.automaticTransfers?.reqDirectDepositMinAmount)) :
			'[Not Set]';
	}

	get reqACHOrBillPayNum() {
		return this.design.requirements.automaticTransfers?.reqACHOrBillPayNum || '[Not Set]';
	}

	get reqACHOrDirectDepositNum() {
		return this.design.requirements.automaticTransfers?.reqACHOrDirectDepositNum || '[Not Set]';
	}

	get reqBillPayOrDirectDepositNum() {
		return this.design.requirements.automaticTransfers?.reqBillPayOrDirectDepositNum || '[Not Set]';
	}

	get reqACHOrBillPayOrDirectDepositNum() {
		return this.design.requirements.automaticTransfers?.reqACHOrBillPayOrDirectDepositNum || '[Not Set]';
	}

	// Online Banking

	get reqOnlineBanking() {
		return this.design.requirements.onlineBanking?.reqOnlineBanking ? 'Yes' : 'No';
	}

	get reqOnlineBankingNum() {
		return this.design.requirements.onlineBanking?.reqOnlineBankingNum || '[Not Set]';
	}

	// Other Requirements

	get others() {
		return this.design.requirements?.others;
	}

	created() {
		if([8, 10, 13].includes(this.design.type) || (this.design.type === 12 && this.design.choiceCheckingDetails?.choiceCheckingModelType === 'qualification')) {
			this.showAutoTransfer = true;
			this.showOnlineBanking = true;
			this.showTransactions = true;
			this.showEStatement = true;
		}

		else if(this.design.type === 15) {
			this.showTransactions = true;
			this.showEStatement = true;
		}
	}

}
