import { Features, FeaturesFactory } from './Features';
export interface SaverRewards {
	[key: string]: unknown;
	features: Features;
}

export const SaverRewardsFactory = function(): SaverRewards {
	return {
		features: FeaturesFactory(),
	} as SaverRewards;
};