













































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { ReductionAmountsFactory } from '@/services/design/ReductionAmounts';
import { CurrencyValidator } from '@/components/form/validations/Currency';

type ReductionAmountsValidation = Validation & {
	[key: string]: unknown;
	reqEStatementReductionAmount: Validation;
	reqOnlineBankingReductionAmount: Validation;
	reqTransReductionAmount: Validation;
};

@Component({
	components: {}
})
export default class ReductionAmounts extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;

	validations(): RuleDecl {
		return {
			designData: {
				reductionAmounts: {
					reqEStatementReductionAmount: {
						CurrencyValidator
					},
					reqOnlineBankingReductionAmount: {
						CurrencyValidator
					},
					reqTransReductionAmount: {
						CurrencyValidator
					},
				}
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.reductionAmounts) {
			this.designData.reductionAmounts = ReductionAmountsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	get reductionAmountsValidation(): ReductionAmountsValidation {
		return this.$v?.designData?.reductionAmounts as ReductionAmountsValidation;
	}

	touch() {
		this.reductionAmountsValidation.$touch();
	}

	reset() {
		this.reductionAmountsValidation.$reset();
	}

	isDirty(): boolean {
		return this.reductionAmountsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.reductionAmountsValidation.$anyError;
	}

	get reqEStatementReductionAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.reductionAmountsValidation.reqEStatementReductionAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

	get reqOnlineBankingReductionAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.reductionAmountsValidation.reqOnlineBankingReductionAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

	get reqTransReductionAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.reductionAmountsValidation.reqTransReductionAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}

}
