
type DataValue = string|number|boolean|null;

interface DataItem {
	[key: string]: DataValue;
}

/**
 * For reactive management of arrays-of-objects
 * All operations mutate the original array
 * 
 * Makes draggable management less of a pain
 */
export default class ListActions {

	/**
	 * The canonical list of items
	 */
	list: Array<DataItem>;

	/**
	 * A default/blank item
	 * New additions to the list will populate a copy of this
	 * 
	 * Vue reactivity cannot detect new object keys -
	 * if you're going to use it for rendering, set it here
	 */
	blank: DataItem;

	/**
	 * Vue's internal keying causes problems with re-indexing
	 * Each item should have a key that is:
	 * - Unique
	 * - NOT tied to current index
	 */
	keyCount = 1;

	/**
	 * Theoretically could change this if it causes problems
	 */
	keyName = '_key_internal_';

	constructor(list: Array<object> = [], blank: object = {}) {
		this.list = list as Array<DataItem>;
		this.blank = blank as DataItem;

		this.resetKeys();
	}

	/**
	 * Add a single item to the list
	 * Uses the standard blank object as a base (default empty),
	 * combines with an optional argument (default empty)
	 */
	add(): this {
		this.list.splice(this.list.length, 0, {...this.blank} as DataItem);
		this.resetKeys();

		return this;
	}

	/**
	 * Removes the item at the specified index
	 * Does not re-key, but as standard with arrays it re-indexes
	 */
	remove(index: number): this {
		this.list.splice(index, 1);

		return this;
	}

	/**
	 * Moves an item from one position in the list to another
	 * Does not handle "swap" type moves, intended for use with draggables
	 */
	move(fromIndex: number, toIndex: number): this {
		const cut = this.list.splice(fromIndex, 1);
		this.list.splice(toIndex, 0, ...cut);

		// this.resetKeys();
		return this;
	}

	length(): number {
		return this.list.length;
	}

	/**
	 * @see keyCount
	 */
	private resetKeys(): void {
		let i;
		const ilen = this.list.length;

		for (i = 0; i < ilen; i++) {
			if (this.list[i][this.keyName]) {
				this.list[i][this.keyName] = this.keyCount++;
			} else {
				// use non-enumerable property to stop from ending up in JSON
				Object.defineProperty(this.list[i], this.keyName, {
					value: this.keyCount++,
					enumerable: false,
					writable: true,
				});
			}
		}
	}

	/**
	 * Retreive the internal key at the given index
	 */
	getKey(index: number): number {
		return this.list[index][this.keyName] as number;
	}


	/**
	 * Updates a value for the item at the given index
	 */
	update(index: number, key: string, value: DataValue): this {
		this.list[index][key] = value as DataValue;

		return this;
	}

	/**
	 * Retrieve the entire item at a given index
	 */
	i(index: number): object {
		return this.list[index];
	}

	// explicit conversions

	toArray(): Array<object> {
		return this.list;
	}

	toString(): string {
		return JSON.stringify(this.toArray(), null, 2);
	}

	// static helpers

	public static add(list: Array<object>, item: object = {}): Array<object> {
		return (new ListActions(list, item)).add().toArray();
	}

	public static remove(list: Array<object>, index: number): Array<object> {
		return (new ListActions(list)).remove(index).toArray();
	}

	public static move(list: Array<object>, fromIndex: number, toIndex: number): Array<object> {
		return (new ListActions(list)).move(fromIndex, toIndex).toArray();
	}

	public static update(list: Array<object>, index: number, key: string, value: DataValue): Array<object> {
		return (new ListActions(list)).update(index, key, value).toArray();
	}

	public static getKey(list: Array<object>, index: number) {
		return (new ListActions(list)).getKey(index);
	}
}

