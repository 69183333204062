























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import ProductDetails from '@/components/form/ProductDetails.vue';
import ProductDesign from '@/components/form/ProductDesign.vue';
import { Validation } from 'vuelidate';

type ValidatedComponent = Vue & { $v: Validation };

@Component({
	components: {
		GeneralInfo,
		ProductDetails,
		ProductDesign
	},
})
export default class ProductTabs extends Vue {
	@Prop({ default: false }) readonly showDesignTab!: boolean;

	tab: number | null = null;
	isMounted = false;

	mounted() {
		// because refs are
		// - not defined at mount
		// - non-reactive
		this.$nextTick(() => {
			this.generalRef = this.$refs.generalInfo as Vue & { $v: Validation };
			this.detailsRef = this.$refs.productDetails as ProductDetails;
			this.designDataRef = this.$refs.productDesign as ProductDesign;
			this.isMounted = true;
		});
	}

	get sliderColor() {
		const error = '#D41A27';
		const valid = '#1976D2';

		switch (this.tab) {
			case 0: // general
				if (this.generalError) {
					return error;
				}
				break;
			case 1: // details
				if (this.detailsError) {
					return error;
				}
				break;
			case 2: // design data
				if (this.designDataError) {
					return error;
				}
				break;
			default:
				break;
		}

		return valid;
	}

	generalRef: ValidatedComponent | null = null;
	detailsRef: ProductDetails | null = null;
	designDataRef: ProductDesign | null = null;

	touch(): void {
		this.generalRef?.$v.$touch();
		this.detailsRef?.touch();
		this.designDataRef?.touch();
	}

	reset(): void {
		this.generalRef?.$v.$reset();
		this.detailsRef?.reset();
		this.designDataRef?.reset();
	}

	get isDirty(): boolean {
		return this.isMounted && (
			this.generalRef?.$v.$anyDirty ||
			this.detailsRef?.isDirty() ||
			this.designDataRef?.isDirty()
		) || false;
	}

	get hasErrors(): boolean {
		return this.isMounted && (
			this.generalError ||
			this.detailsError ||
			this.designDataError
		) || false;
	}

	get generalError(): boolean {
		return this.isMounted && this.generalRef?.$v?.$anyError || false;
	}

	get detailsError(): boolean {
		return this.isMounted && this.detailsRef?.hasError() || false;
	}

	get designDataError(): boolean {
		return this.isMounted && this.designDataRef?.hasError() || false;
	}
}
