
























































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { InControlDetailsFactory } from '@/services/design/InControlDetails';
import { CurrencyValidator } from '@/components/form/validations/Currency';

type InControlDetailsValidation = Validation & {
	[key: string]: unknown;
	extendProductType: Validation;
	monthlyFeeAmount: Validation;
	monthlyFeeName: Validation;
};

@Component({
	components: {
	}
})

export default class InControlDetails extends Vue implements DesignSection {
	@Prop({}) designData!: DesignData;

	isMounted = false;

	validations(): RuleDecl {
		return {
			designData: {
				inControlDetails: {
					extendProductType: {},
					monthlyFeeAmount: {
						CurrencyValidator
					},
					monthlyFeeName: {}
				},
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.inControlDetails) {
			this.designData.inControlDetails = InControlDetailsFactory();
		}
	}

	mounted() {
		this.isMounted = true;
	}

	get inControlDetailsValidation(): InControlDetailsValidation {
		return this.$v.designData.inControlDetails as InControlDetailsValidation;
	}

	touch() {
		this.inControlDetailsValidation.$touch();
	}

	reset() {
		this.inControlDetailsValidation.$reset();
	}

	isDirty(): boolean {
		return this.inControlDetailsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.inControlDetailsValidation.$anyError;
	}

	get monthlyFeeAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.inControlDetailsValidation.monthlyFeeAmount?.$invalid) {
			return 'Only dollar amounts are allowed.';
		}
		return null;
	}

}
