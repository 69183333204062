












































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { integer, required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { OnlineBanking as IOnlineBanking } from '@/services/design/OnlineBanking';
import { KText } from '@kasasa/fbase-components';

type OnlineBankingValidation = Validation & {
	[key: string]: unknown;
	reqOnlineBanking: Validation;
	reqOnlineBankingNum: Validation;
};

@Component({
	components: {
		KText
	}
})
export default class OnlineBanking extends Vue implements DesignSection {
	@Prop({}) onlineBanking!: IOnlineBanking;

	isMounted = false;

	validations(): RuleDecl {
		return {
			onlineBanking: {
				reqOnlineBanking: {},
				reqOnlineBankingNum: {
					integer, required
				},
			}
		} as RuleDecl;
	}

	mounted() {
		this.isMounted = true;
	}

	get onlineBankingValidation(): OnlineBankingValidation {
		return this.$v.onlineBanking as OnlineBankingValidation;
	}

	touch() {
		this.onlineBankingValidation.$touch();
	}

	reset() {
		this.onlineBankingValidation.$reset();
	}

	isDirty(): boolean {
		return this.onlineBankingValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.onlineBankingValidation.$anyError;
	}

	get reqOnlineBankingNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.onlineBankingValidation.reqOnlineBankingNum?.$invalid) {
			return 'Only numbers are allowed';
		}
		return null;
	}
}
