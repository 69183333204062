


















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Rates } from '@/services/design/Rates';
import { KReadOnly } from '@kasasa/fbase-components';
import { addPercentSuffix } from "@/filters/Percent";
import { CurrencyFilter } from "@/filters/Currency";

@Component({
	components: {
		KReadOnly
	}
})
export default class ProductRates extends Vue {
	@Prop({}) readonly rates!: Rates;
	@Prop({}) readonly type!: number;

	formatValue(value: string, type: string) {
		switch(type) {
			case 'percent':
				return addPercentSuffix(value);
			case 'currency':
				return CurrencyFilter(value);
		}
	}

	isMultiTier(): boolean {
		let numTiers = 0;
		if (this.rates) {
			if (this.rates.baseAPY) {
				numTiers++;
			}
			if (this.rates.secondTierAPY) {
				numTiers++;
			}
			if (this.rates.thirdTierAPY) { 
				numTiers++;
			}
		}
		return numTiers > 1;
	}

	shouldExist(rate: string) {
		const value = parseFloat(rate);
		return !isNaN(value);
	}
}
