

















































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { RatesFactory } from '@/services/design/Rates';
import { Rates as Rate } from '@/services/design/Rates';
import { CurrencyValidator } from '@/components/form/validations/Currency';
import { RatesValidator, RatesValidator5 } from '@/components/form/validations/Rates';

import { KText } from '@kasasa/fbase-components';

@Component({
	components: {
		KText
	}
})
export default class Rates extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;

	// Base ITR
	get baseItr() {
		return this.designData.rates?.baseItr.toString() || '';
	}

	set baseItr(i: string) {
		if (this.designData.rates) {
			this.designData.rates.baseItr = parseFloat(i) || 0;
		}
	}

	get baseItrErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.baseItr.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Below Cap ITR
	get belowCapItr() {
		return this.designData.rates?.belowCapItr.toString() || '';
	}

	set belowCapItr(i: string) {
		if (this.designData.rates) {
			this.designData.rates.belowCapItr = parseFloat(i) || 0;
		}
	}

	get belowCapItrErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.belowCapItr.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}
	
	// Above Cap ITR
	get aboveCapItr() {
		return this.designData.rates?.aboveCapItr.toString() || '';
	}

	set aboveCapItr(i: string) {
		if (this.designData.rates) {
			this.designData.rates.aboveCapItr = parseFloat(i) || 0;
		}
	}

	get aboveCapItrErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.aboveCapItr.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Base APY
	get baseAPY() {
		return this.designData.rates?.baseAPY.toString() || '';
	}

	set baseAPY(i: string) {
		if (this.designData.rates) {
			this.designData.rates.baseAPY = parseFloat(i) || 0;
		}
	}

	get baseAPYErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.baseAPY.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Below Cap APY
	get belowCapAPY() {
		return this.designData.rates?.belowCapAPY.toString() || '';
	}

	set belowCapAPY(i: string) {
		if (this.designData.rates) {
			this.designData.rates.belowCapAPY = parseFloat(i) || 0;
		}
	}

	get belowCapAPYErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.belowCapAPY.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Bonus APY
	get bonusAPY() {
		return this.designData.rates?.bonusAPY.toString() || '';
	}

	set bonusAPY(i: string) {
		if (this.designData.rates) {
			this.designData.rates.bonusAPY = parseFloat(i) || 0;
		}
	}

	get bonusAPYErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.bonusAPY.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Bonus Cap
	get bonusCap() {
		return this.designData.rates?.bonusCap || '';
	}

	set bonusCap(i: string) {
		if (this.designData.rates) {
			this.designData.rates.bonusCap = i;
		}
	}

	get bonusCapErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.bonusCap.$error ? 'Only dollar amounts are allowed' : '')
		) || null;
	}

	// Second Tier APY
	get secondTierAPY() {
		return this.designData.rates?.secondTierAPY.toString() || '';
	}

	set secondTierAPY(i: string) {
		if (this.designData.rates) {
			this.designData.rates.secondTierAPY = parseFloat(i) || 0;
		}
	}

	get secondTierAPYErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.secondTierAPY.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	// Second Tier Cap
	get secondTierCap() {
		return this.designData.rates?.secondTierCap || '';
	}

	set secondTierCap(i: string) {
		if (this.designData.rates) {
			this.designData.rates.secondTierCap = i;
		}
	}

	get secondTierCapErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.secondTierCap.$error ? 'Only dollar amounts are allowed' : '')
		) || null;
	}

	// Third Tier APY
	get thirdTierAPY() {
		return this.designData.rates?.thirdTierAPY.toString() || '';
	}

	set thirdTierAPY(i: string) {
		if (this.designData.rates) {
			this.designData.rates.thirdTierAPY = parseFloat(i) || 0;
		}
	}

	get thirdTierAPYErrorMessage() {
		return (
			this.isMounted &&
			(this.$v.designData.rates?.thirdTierAPY.$error ? 'Only percent amounts are allowed' : '')
		) || null;
	}

	validations(): RuleDecl {
		return {
			designData: {
				rates: {
					baseAPY: { RatesValidator },
					belowCapAPY: { RatesValidator },
					bonusAPY: { RatesValidator },
					bonusCap: { CurrencyValidator },
					secondTierAPY: { RatesValidator },
					secondTierCap: { CurrencyValidator },
					thirdTierAPY: { RatesValidator },
					baseItr: { RatesValidator5 },
					belowCapItr: { RatesValidator5 },
					aboveCapItr: { RatesValidator5 }
				},
			}
		} as RuleDecl;
	}
	
	created() {
		if (!this.designData.rates) {
			this.designData.rates = RatesFactory();
		}
		this.designData.rates = this.removeNulls(this.designData.rates);
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	touch() {
		this.$v.designData.rates?.$touch();
	}

	reset() {
		this.$v.designData.rates?.$reset();
	}

	isDirty(): boolean {
		return this.$v.designData.rates?.$anyDirty || false;
	}

	hasError(): boolean {
		return this.$v.designData.rates?.$anyError || false;
	}

	removeNulls(rates: Rate | null) {
		const tempRates = rates || RatesFactory();
		tempRates.baseAPY = tempRates.baseAPY ===  null ? 0 : tempRates.baseAPY;
		tempRates.bonusAPY = tempRates.bonusAPY === null ? 0 : tempRates.bonusAPY;
		tempRates.bonusCap = tempRates.bonusCap === null ? '0.00' : tempRates.bonusCap;
		tempRates.secondTierAPY = tempRates.secondTierAPY === null ? 0 : tempRates.secondTierAPY;
		tempRates.secondTierCap = tempRates.secondTierCap === null ? '0.00' : tempRates.secondTierCap;
		tempRates.thirdTierAPY = tempRates.thirdTierAPY === null ? 0 : tempRates.thirdTierAPY;
		tempRates.thirdTierCap = tempRates.thirdTierCap === null ? '0.00' : tempRates.thirdTierCap;
		return tempRates;
	}
}
