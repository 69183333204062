export interface Rates {
	baseAPY: number;
	belowCapAPY: number;
	bonusAPY: number;
	bonusCap: string;
	secondTierAPY: number;
	secondTierCap: string;
	thirdTierAPY: number;
	thirdTierCap: string;
	baseItr: number;
	belowCapItr: number;
	aboveCapItr: number;
}
export const RatesFactory = function(): Rates {
	return {
		baseAPY: 0.00,
		belowCapAPY: 0,
		bonusAPY: 0,
		bonusCap: '0.00',
		secondTierAPY: 0,
		secondTierCap: '0.00',
		thirdTierAPY: 0,
		thirdTierCap: '0.00',
		baseItr: 0,
		belowCapItr: 0,
		aboveCapItr: 0,
	} as Rates;
};