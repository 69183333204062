

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Product from '@/services/Product';

@Component({
	components: {
	}
})
export default class GeneralInfo extends Vue {
	@Prop({default: false}) readonly product !: Product;
}
