
































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import DesignData from '@/services/DesignData';
import { RequirementsFactory } from '@/services/design/Requirements';
import { ProductTypesEnum } from '@/services/ProductType';

import Transactions from '@/components/form/design/Transactions.vue';
import AutomaticTransfers from '@/components/form/design/AutomaticTransfers.vue';
import EStatement from '@/components/form/design/EStatement.vue';
import OnlineBanking from '@/components/form/design/OnlineBanking.vue';
import OtherRequirements from '@/components/form/design/OtherRequirements.vue';

type ValidatedComponent = Vue & {
	hasError: () => boolean;
	isDirty: () => boolean;
	reset: () => void;
	touch: () => void;
};

@Component({
	components: {
		Transactions,
		AutomaticTransfers,
		EStatement,
		OnlineBanking,
		OtherRequirements
	}
})
export default class Requirements extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;

	created() {
		if (!this.designData.requirements) {
			this.designData.requirements = RequirementsFactory();
		}
		this.setDesignData(this.designData);
	}

	transactionsRef: Transactions & ValidatedComponent | null = null;
	automaticTransfersRef: AutomaticTransfers & ValidatedComponent | null = null;
	eStatementRef: EStatement & ValidatedComponent | null = null;
	onlineBankingRef: OnlineBanking & ValidatedComponent | null = null;
	otherRequirementsRef: OtherRequirements & ValidatedComponent | null = null;

	mounted() {
		this.$nextTick(() => {
			this.transactionsRef = this.$refs.transactions as Transactions & ValidatedComponent;
			this.automaticTransfersRef = this.$refs.automaticTransfers as AutomaticTransfers & ValidatedComponent;
			this.eStatementRef = this.$refs.eStatement as EStatement & ValidatedComponent;
			this.onlineBankingRef = this.$refs.onlineBanking as OnlineBanking & ValidatedComponent;
			this.otherRequirementsRef = this.$refs.otherRequirements as OtherRequirements & ValidatedComponent;

			this.isMounted = true;
		});
	}

	get showOtherRequirements() { // Show all requirements including Other Requirements
		return [ProductTypesEnum.RewardChecking, ProductTypesEnum.Tunes, ProductTypesEnum.Cashback].includes(this.designData.type);
	}

	get showRequirements() { // Excludes Other Requirements
		return this.designData.type === ProductTypesEnum.ChoiceChecking && this.designData.choiceCheckingDetails?.choiceCheckingModelType === 'qualification';
	}

	touch() {
		this.transactionsRef?.touch();
		this.automaticTransfersRef?.touch();
		this.eStatementRef?.touch();
		this.onlineBankingRef?.touch();
		this.otherRequirementsRef?.touch();
	}

	reset() {
		this.transactionsRef?.reset();
		this.automaticTransfersRef?.reset();
		this.eStatementRef?.reset();
		this.onlineBankingRef?.reset();
		this.otherRequirementsRef?.reset();
	}

	isDirty(): boolean {
		return this.isMounted && (
			this.transactionsRef?.isDirty() ||
			this.automaticTransfersRef?.isDirty() ||
			this.eStatementRef?.isDirty() ||
			this.onlineBankingRef?.isDirty() ||
			this.otherRequirementsRef?.isDirty()
		) || false;
	}

	hasError(): boolean {
		return this.isMounted && (
			this.transactionsRef?.hasError() ||
			this.automaticTransfersRef?.hasError() ||
			this.eStatementRef?.hasError() ||
			this.onlineBankingRef?.hasError() ||
			this.otherRequirementsRef?.hasError()
		) || false;
	}

}
