
// these don't do anything functionally different
// they're for peace of mind & documentation of what each is supposed to be
type integer = string | number | null;
type decimal = string | number | null;

/*
 * Reference as of Sept 2020 for types:
 
 * 		+-----------------------------+---------------------------+------+----------+
 * 		| Field                       | Type                      | Null | Default  |
 * 		+-----------------------------+---------------------------+------+----------+
 * 		| atmRefundEnabled            | tinyint(1)                | YES  | 0        |
 * 		| atmRefundUnlimited          | tinyint(1)                | YES  | 0        |
 * 		| atmRefundCycleCap           | int(11)                   | YES  | NULL     |
 * 		| atmRefundItemCap            | int(11)                   | YES  | NULL     |
 * 		| atmReceiptDisclaimerEnabled | tinyint(1)                | YES  | 1        |
 * 		| atmRefundMod                | decimal(10,2)             | YES  | 0.00     |
 * 		| atmInstanceCap              | int(10)                   | YES  | NULL     |
 * 		| atmRefundScope              | enum('national','global') | YES  | national |
 * 		+-----------------------------+---------------------------+------+----------+
*/

export interface AtmRefund {
	atmRefundEnabled: boolean;
	atmRefundScope: 'national' | 'global';
	atmRefundUnlimited: boolean;
	atmRefundCycleCap: integer;
	atmRefundItemCap: integer;
	atmInstanceCap: integer;
	atmReceiptDisclaimerEnabled: boolean;
	atmRefundMod: decimal;
}

export const AtmRefundFactory = function(): AtmRefund {
	return {
		atmRefundEnabled: false,
		atmRefundScope: 'national',
		atmRefundUnlimited: false,
		atmRefundCycleCap: 0,
		atmRefundItemCap: 0,
		atmInstanceCap: 0,
		atmReceiptDisclaimerEnabled: false,
		atmRefundMod: 0,
	} as AtmRefund;
};
