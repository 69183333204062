
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DesignData from '@/services/DesignData';
import { ProductTypesEnum } from '@/services/ProductType';
import DesignBase from './design/Base.vue';
import Requirements from './design/Requirements.vue';
import ReductionAmounts from './design/ReductionAmounts.vue';
import CashbackRates from './design/CashbackRates.vue';
import Conditions from './design/Conditions.vue';
import ProductRates from "./design/ProductRates.vue";
import InControlDetails from "./design/InControlDetails.vue";
import ChoiceChecking from './design/ChoiceCheckingDetails.vue';
import SaverDetails from './design/SaverDetails.vue';
import ProductService from "@/services/ProductService";
import { ExpandableFilter } from "@kasasa/fbase-components/lib";
import CheckingRewards from "./design/CheckingRewards.vue";
import SaverRewards from "./design/SaverRewards.vue";
import TunesDetails from "./design/TunesDetails.vue";

@Component({
	components: {
		DesignBase,
		Requirements,
		ReductionAmounts,
		CashbackRates,
		ProductRates,
		SaverDetails,
		Conditions,
		InControlDetails,
		CheckingRewards,
		ChoiceChecking,
		SaverRewards,
		TunesDetails
	}
})

export default class ProductDesign extends Vue {
	@Prop({}) readonly design !: DesignData;

	saverAssociatedProductName = '';
	mounted() {
		if (this.design.type === ProductTypesEnum.Saver) {
			const productService = new ProductService(this.$store);
			const filter = (new ExpandableFilter);
			const saverAssociatedProduct = this.design.saverDetails?.saverAssociatedProduct;

			if (saverAssociatedProduct) {
				productService.find(this.$route.params.clientId, saverAssociatedProduct.toString(), filter)
					.then(resp => {
						if (resp.data.data.enabled === 0) {
							this.saverAssociatedProductName = resp.data.data.name + ' [Inactive]';
						} else {
							this.saverAssociatedProductName = resp.data.data.name;
						}
					})
					.catch(e => {
						if (e.response.status === 404) {
							this.saverAssociatedProductName = 'Not Set';
						}
					});
			} else {
				this.saverAssociatedProductName = 'Not Set';
			}
		}
	}

}

