export interface AutomaticTransfers {
	reqAutoTransferType: string;
	reqACHNum: number | null;
	reqACHMinAmount: number | null;
	reqBillPayNum: number | null;
	reqDirectDepositNum: number | null;
	reqDirectDepositMinAmount: number | null;
	reqACHOrBillPayNum: number | null;
	reqACHOrDirectDepositNum: number | null;
	reqBillPayOrDirectDepositNum: number | null;
	reqACHOrBillPayOrDirectDepositNum: number | null;
}

export const AutomaticTransfersFactory = function(): AutomaticTransfers {
	return {
		reqAutoTransferType: '',
		reqACHNum: null,
		reqACHMinAmount: null,
		reqBillPayNum: null,
		reqDirectDepositNum: null,
		reqDirectDepositMinAmount: null,
		reqACHOrBillPayNum: null,
		reqACHOrDirectDepositNum: null,
		reqBillPayOrDirectDepositNum: null,
		reqACHOrBillPayOrDirectDepositNum: null,
	} as AutomaticTransfers;
};

export const AutoTransfers = [
	{
		type: 'all',
		label: '(All)',
		display: [
			'reqACHNum',
			'reqACHMinAmount',
			'reqBillPayNum',
			'reqDirectDepositNum',
			'reqDirectDepositMinAmount',
			'reqACHOrBillPayNum',
			'reqACHOrDirectDepositNum',
			'reqBillPayOrDirectDepositNum',
			'reqACHOrBillPayOrDirectDepositNum'
		]
	},
	{
		type: 'none',
		label: '(None)',
		display: []
	},
	{
		type: 'a',
		label: 'ACH',
		display: ['reqACHNum', 'reqACHMinAmount']
	},
	{
		type: 'b',
		label: 'Bill Pay',
		display: ['reqBillPayNum']
	},
	{
		type: 'd',
		label: 'Direct Deposit',
		display: ['reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
	{
		type: 'a&b',
		label: 'ACH & Bill Pay',
		display: ['reqACHNum', 'reqACHMinAmount', 'reqBillPayNum']
	},
	{
		type: 'a&d',
		label: 'ACH & Direct Deposit',
		display: ['reqACHNum', 'reqACHMinAmount', 'reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
	{
		type: 'b&d',
		label: 'Bill Pay & Direct Deposit',
		display: ['reqBillPayNum', 'reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
	{
		type: 'a|b',
		label: 'ACH or Bill Pay',
		display: ['reqACHMinAmount', 'reqACHOrBillPayNum']
	},
	{
		type: 'a|d',
		label: 'ACH or Direct Deposit',
		display: ['reqACHMinAmount', 'reqACHOrDirectDepositNum']
	},
	{
		type: 'b|d',
		label: 'Bill Pay or Direct Deposit',
		display: ['reqDirectDepositMinAmount', 'reqBillPayOrDirectDepositNum']
	},
	{
		type: '(a&b)|d',
		label: 'ACH & Bill Pay, or Direct Deposit',
		display: ['reqACHMinAmount', 'reqBillPayNum', 'reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
	{
		type: '(a&d)|b',
		label: 'ACH & Direct Deposit, or Bill Pay',
		display: ['reqACHNum', 'reqACHMinAmount', 'reqBillPayNum', 'reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
	{
		type: '(b&d)|a',
		label: 'Bill Pay & Direct Deposit, or ACH',
		display: ['reqACHNum','reqACHMinAmount','reqBillPayNum','reqDirectDepositNum','reqDirectDepositMinAmount']
	},
	{
		type: '(a|b)&d',
		label: 'ACH or Bill Pay, & Direct Deposit',
		display: ['reqACHMinAmount', 'reqDirectDepositNum', 'reqDirectDepositMinAmount', 'reqACHOrBillPayNum']
	},
	{
		type: '(a|d)&b',
		label: 'ACH or Direct Deposit, and Bill Pay',
		display: ['reqACHMinAmount', 'reqBillPayNum', 'reqDirectDepositMinAmount', 'reqACHOrDirectDepositNum']
	},
	{
		type: '(b|d)&a',
		label: 'Bill Pay or Direct Deposit, & ACH',
		display: ['reqACHNum', 'reqACHMinAmount', 'reqBillPayOrDirectDepositNum']
	},
	{
		type: 'b|d|a',
		label: 'Bill Pay or Direct Deposit or ACH',
		display: ['reqACHMinAmount', 'reqDirectDepositMinAmount', 'reqACHOrBillPayOrDirectDepositNum']
	},
	{
		type: 'b&d&a',
		label: 'Bill Pay & Direct Deposit & ACH',
		display: ['reqACHNum', 'reqACHMinAmount', 'reqBillPayNum', 'reqDirectDepositNum', 'reqDirectDepositMinAmount']
	},
];

export const getAutoTransferType = (type: string) => {
	return AutoTransfers.find(autoTransfer => autoTransfer.type === type);
};

export const AutoTransferMap = AutoTransfers
	.slice(1) // remove (All) since it is only for testing purposes
	.map((autoTransfer: { type: string; label: string; display: string[] }) => ({
		text: autoTransfer.label,
		value: autoTransfer.type
	}));