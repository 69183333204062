









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SaverRewards as SaverRewardsInterface } from "@/services/design/SaverRewards";
import Features from "./Features.vue";

@Component({
	components: {
		Features
	}
})
export default class SaverRewards extends Vue {
	@Prop({}) readonly saverRewards!: SaverRewardsInterface;
}

