import '@/hooks';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import Vuelidate from 'vuelidate';
import { fiLockDown, appModeCheck } from '@kasasa/fbase-components/lib';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

// use following if you want /:clientId lockdown
router.beforeEach(fiLockDown(store));

// use following if you do NOT want /:clientId lockdown
// store.dispatch('auth/initializePortalSession', store);

// assumes fbase-portalapp proxy of /api/session/portalapp-status
router.beforeEach(appModeCheck(store)); 

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
