













































import Vue from 'vue';
import Product from '@/services/Product';
import { Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import DclAdd from '@/components/DraggableCardList/DclAdd.vue';
import DclCard from '@/components/DraggableCardList/DclCard.vue';
import DclGrid from '@/components/DraggableCardList/DclGrid.vue';
import ListActions from '@/components/DraggableCardList/ListActions';
import { RuleDecl } from 'vue/types/options';
import { requiredIf } from 'vuelidate/lib/validators';
import { tinyMce } from '@/services/TinyMce';
import Editor from '@tinymce/tinymce-vue';

@Component({
	components: {
		DclAdd,
		DclCard,
		DclGrid,
		Editor,
	},
})
export default class ProductDetails extends Vue {
	@State('product') product!: Product;
	tinyMce = tinyMce;

	isMounted = false;
	getCardTitle(index: number) {
		return `DETAILS ${index + 1}`;
	}
	get productDetails() {
		return this.product.details;
	}

	move(fromIndex: number, toIndex: number) {
		this.touch();
		return ListActions.move(this.product.details, fromIndex, toIndex);
	}

	add() {
		return ListActions.add(this.product.details, { name: '', content: '' });
	}

	remove(index: number) {
		return ListActions.remove(this.product.details, index);
	}

	mounted() {
		this.isMounted = true;
	}

	validations(): RuleDecl {
		return {
			product: {
				details: {
					$each: {
						name: {
							required: requiredIf((detail) => {
								return (
									!this.isEmpty(detail.content) && this.isEmpty(detail.name)
								);
							}),
						},
						content: {
							required: requiredIf((detail) => {
								return (
									this.isEmpty(detail.content) && !this.isEmpty(detail.name)
								);
							}),
						},
					},
				},
			},
		} as RuleDecl;
	}

	isEmpty(content: string | null): boolean {
		return content === '' || content === null || content === undefined;
	}

	getNameErrorMessage(index: number) {
		if (
			!this.isEmpty(this.product.details[index].content) &&
			this.isEmpty(this.product.details[index].name)
		) {
			return 'Details Name is required.';
		} else {
			return '';
		}
	}

	getContentErrorMessage(index: number) {
		if (
			this.isEmpty(this.product.details[index].content) &&
			!this.isEmpty(this.product.details[index].name)
		) {
			return 'Details Content is required.';
		} else {
			return '';
		}
	}

	touch(): void {
		this.$v.product.details?.$touch();
	}

	reset(): void {
		this.$v.product.details?.$reset();
	}

	isDirty(): boolean {
		return this.$v.product.details?.$dirty || false;
	}

	hasError(): boolean {
		if (!this.isMounted) {
			return false;
		}
		return this.$v.product.details?.$invalid || false;
	}
}
