
/**
 * Returns a validation function that checks for:
 * - The given number of decimal places, or
 * - No decimal places (no decimal point)
 * Meant to be used with vuelidate "decimal"
 */
export default function validateDecimalPlaces(places: number): (value: string) => boolean {
	return function (value: string): boolean {
		if (!value) {
			return true;
		}
	
		if (!/\./.test(value)) {
			return true; // no decimal, let vuelidate handle it
		}

		return (new RegExp('\\.\\d{' + places + '}$')).test(value);
	};
}






