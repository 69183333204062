





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { KCardHeaderBtn, KCardHeader, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import { Dialog, Alert, NoticeClass, NoticeResponse } from '@kasasa/fbase-components/lib';
import { Route } from 'vue-router';
import BatchCreate from '@/components/form/BatchCreate.vue';
import { BatchProduct } from '@/services/Product';
import ProductService from '@/services/ProductService';

@Component({
	components: {
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		BatchCreate,
	},
})
export default class BatchCreatePage extends Vue {
	@State('batch') batch!: Array<BatchProduct>;
	@Action('setBatch') setBatch!: Function;

	productApiService = new ProductService(this.$store);

	title = 'Add Multiple New Products';

	get items(): Array<object> {
		return [
			{
				key: '0',
				text: 'Products',
				disabled: false,
				link: true,
				exact: true,
				to: {
					name: 'Products',
					params: { clientId: this.$route.params.clientId },
				},
			},
			{
				key: '1',
				text: this.title,
				disabled: true,
			},
		];
	}

	get batchCreate(): BatchCreate {
		return this.$refs.batchCreate as BatchCreate;
	}

	checkForErrors(): boolean {
		let errors = false;

		this.batchCreate?.touch();
		errors = this.batchCreate?.hasError();

		if (errors) {
			const broken = new Alert(
				'Unable to save. Please make sure all required fields are completed without errors.',
				NoticeClass.ERROR,
			);
			this.$store.dispatch('notices/add', broken);
		}

		// todo, set error for nothing

		return errors;
	}

	checkForDirty(): boolean {
		return this.batchCreate?.isDirty();
	}

	async beforeRouteLeave(to: Route, from: Route, next: Function) {
		if (this.checkForDirty()) {
			const dialog = new Dialog(
				'Unsaved Changes',
				'You have unsaved changes on this page that will be lost if you leave now. Are you sure?',
				'LEAVE WITHOUT SAVING',
			);
			dialog.setDeclineLabel('STAY ON THIS PAGE').setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.setBatch([]);
					this.batchCreate?.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.setBatch([]);
			next();
		}
	}

	cancelAction() {
		this.batchCreate?.touch();
		this.$router.push({
			name: 'Products',
			params: this.$route.params,
		});
	}

	saveCloseAction() {
		if (this.checkForErrors()) {
			return;
		}

		const len = this.batch.length;

		const savedNotice = new Alert(
			`${len} product${len === 1 ? ' has' : 's have'} been successfully saved.`,
			NoticeClass.SUCCESS,
		);
		savedNotice.setTimeout(6000);

		return new Promise(resolve => {
			this.productApiService
				.batchCreate(this.$route.params.clientId, this.batch)
				.then(() => {
					this.setBatch([]);
					this.batchCreate?.reset();
					this.$store.dispatch('notices/add', savedNotice);
					this.$router.push({
						name: 'Products',
						params: this.$route.params,
					});
					resolve();
				})
				.catch(() => {
					// generic error, since this is not expected to return any specific error;
					// batch create doesn't specify validation in its returns or anything
					const whoops = new Alert(
						'Unable to save. Please make sure all required fields are completed without errors.',
						NoticeClass.ERROR,
					);
					this.$store.dispatch('notices/add', whoops);
					resolve();
				});
		});
	}
}
