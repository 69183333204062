










































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component({})
export default class BatchAddBulk extends Vue {
	
	dialog = false;
	bulkAmount = 1;

	@Watch('dialog')
	function() {
		if (!this.dialog) {
			this.bulkAmount = 1;
		}
	}

	readonly maxBulkAmount = 50;

	get selectItems(): Array<number> {
		return [...Array(this.maxBulkAmount).keys()].map(i => i + 1);
	}

}

