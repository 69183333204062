
































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import { EStatement as IEStatement } from '@/services/design/EStatement';
import { KRadioGroup } from '@kasasa/fbase-components';

type EStatementValidation = Validation & {
	[key: string]: unknown;
	reqEStatement: Validation;
	reqEStatementView: Validation;
};

@Component({
	components: {
		KRadioGroup,
	}
})
export default class EStatement extends Vue implements DesignSection {
	@Prop({}) eStatement!: IEStatement;

	readonly YesNoItems = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];

	isMounted = false;

	validations(): RuleDecl {
		return {
			eStatement: {
				reqEStatement: {},
				reqEStatementView: {},
			}
		} as RuleDecl;
	}

	mounted() {
		this.isMounted = true;
	}

	get eStatementValidation(): EStatementValidation {
		return this.$v.eStatement as EStatementValidation;
	}

	touch() {
		this.eStatementValidation.$touch();
	}

	reset() {
		this.eStatementValidation.$reset();
	}

	isDirty(): boolean {
		return this.eStatementValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.eStatementValidation.$anyError;
	}

}
