export const RatesValidator = (value: string | null) => {
	const ratesRe = /^(0|[1-9][0-9]*)(\.\d{0,2})?$/;
	if (!value || +value == 0) {
		return true;
	}
	return ratesRe.test(value); 
};

export const RatesValidator5 = (value: string | null) => {
	const ratesRe = /^(0|[1-9][0-9]*)(\.\d{0,5})?$/;
	if (!value || +value == 0) {
		return true;
	}
	return ratesRe.test(value); 
};