





























































import { Component, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { State } from 'vuex-class';
import Product from '@/services/Product';
import { ProductTypeList, getProductTypeName, ProductTypesEnum } from '@/services/ProductType';
import { KReadOnly, KText, KSelect } from '@kasasa/fbase-components';
import Vue from 'vue';

@Component({
	components: {
		KReadOnly, KText, KSelect
	},
})
export default class ProductHeader extends Vue {
	@State('product') product!: Product;
	@Prop({default: false}) readonly isUserExternal !: boolean;

	get productTypes(): Array<object> {
		const list = ProductTypeList
			.filter((productType) => !this.isUserExternal 
				|| (productType.displayHeader && !this.isRewardProduct(productType.id)));

		return list	
			.sort((a, b) => (a.name > b.name) ? 1 : -1)
			.map((productType: { name: string; id: number }) => {
				return {
					text: productType.name,
					value: productType.id,
				};
			});
	}

	isRewardProduct(type: number): boolean {
		return type >= ProductTypesEnum.RewardChecking;
	}

	get canChangeType(): boolean {
		return !this.isUserExternal || !this.isRewardProduct(this.product.fkProductType);
	}

	get productType(): number {
		return this.product.fkProductType;
	}
	set productType(i: number) {
		this.product.fkProductType = i;
		this.product.designData.type = i;
	}

	getProductTypeName(fkProductType: number | null): string {
		return getProductTypeName(fkProductType);
	}

	validations(): RuleDecl {
		return {
			product: {
				name: {
					required
				},
				fkProductType: {
					required
				},
				enabled: {
					required
				}
			}
		};
	}

	get nameErrors() {
		return this.$v?.product.name?.$error ? 'Name is required.' : '';
	}
	get productTypeErrors() {
		return this.$v?.product.fkProductType?.$error ? 'Product Type is required.' : '';
	}
}
