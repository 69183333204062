









































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { KReadOnly } from "@kasasa/fbase-components";
import { Features as FeaturesInterface } from "@/services/design/Features";

@Component({
	components: {
		KReadOnly
	}
})
export default class Features extends Vue {
	@Prop({}) readonly features!: FeaturesInterface;

	isYesNo(value: boolean): string {
		return value === true ? 'Yes' : 'No';
	}
}
