























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import CheckingRewards from '@/components/form/design/CheckingRewards.vue';
import Requirements from '@/components/form/design/Requirements.vue';
import ChoiceCheckingDetails from '@/components/form/design/ChoiceCheckingDetails.vue';
import ReductionAmounts from '@/components/form/design/ReductionAmounts.vue';

import DesignData from '@/services/DesignData';

@Component({
	components: {
		CheckingRewards,
		Requirements,
		ChoiceCheckingDetails,
		ReductionAmounts,
	}
})
export default class ChoiceChecking extends Vue {
	@Prop({}) designData!: DesignData;

	checkingRewards: CheckingRewards | null = null;
	choiceCheckingDetails: ChoiceCheckingDetails | null = null;
	requirements: Requirements | null = null;
	reductionAmounts: ReductionAmounts | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.checkingRewards = this.$refs.checkingRewards as CheckingRewards;
			this.choiceCheckingDetails = this.$refs.choiceCheckingDetails as ChoiceCheckingDetails;
			this.requirements = this.$refs.requirements as Requirements;
			this.reductionAmounts = this.$refs.reductionAmounts as ReductionAmounts;
			this.isMounted = true;
		});
	}

	touch(): void {
		this.checkingRewards?.touch();
		this.choiceCheckingDetails?.touch();
		this.requirements?.touch();
		this.reductionAmounts?.touch();
	}

	reset(): void {
		this.checkingRewards?.reset();
		this.choiceCheckingDetails?.reset();
		this.requirements?.reset();
		this.reductionAmounts?.reset();
	}

	isDirty(): boolean {
		return this.isMounted && (
			this.checkingRewards?.isDirty() ||
			this.choiceCheckingDetails?.isDirty() ||
			this.requirements?.isDirty() ||
			this.reductionAmounts?.isDirty()
		) || false;
	}

	hasError(): boolean {
		return this.isMounted && (
			this.checkingRewards?.hasError() ||
			this.choiceCheckingDetails?.hasError() ||
			this.requirements?.hasError() ||
			this.reductionAmounts?.hasError()
		) || false;
	}

}


