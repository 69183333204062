



















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Product from '@/services/Product';
import { Validation } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import Editor from '@tinymce/tinymce-vue';
import { tinyMce } from '@/services/TinyMce';

type GeneralInfoValidation = Validation & {
	[key: string]: unknown;
	title: Validation;
	general: {
		description: Validation;
		alternateDescription: Validation;
		disclaimer: Validation;
	};
};

@Component({
	components: {
		Editor
	}
})

export default class GeneralInfo extends Vue {
	@State('product') product!: Product;

	@Watch('product.name')
	updateHideTitle() {
		if (this.hideTitle === null) {
			this.hideTitle = this.product.name === this.product.title;
		} else {
			if (this.hideTitle) {
				this.product.title = this.product.name;
			}
		}
	}

	isMounted = false;
	hideTitle: boolean | null = null;
	tinyMce = tinyMce;

	validations(): RuleDecl {
		return {
			product: {
				title: {
					required,
				},
				general: {
					description: {},
					alternateDescription: {},
					disclaimer: {}
				}
			}
		} as RuleDecl;
	}

	mounted() {
		this.isMounted = true;
		this.updateHideTitle();
	}

	handleDisplayName() {
		if (this.hideTitle) {
			this.product.title = this.product.name;
		}
	}

	get titleErrorMessage(): string | null {
		return this.isMounted ? (
			this.$v?.product.title?.$error ? 'Display Name is required.' : null
		) : null;
	}

	reset() {
		this.$v.product?.$reset();
	}

	touch() {
		this.$v.product?.$touch();
	}

	isDirty(): boolean {
		return this.$v.product?.$anyDirty || false;
	}

	hasError(): boolean {
		return this.$v.product?.$anyError || false;
	}

}
