










import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import DesignData from '@/services/DesignData';
import { SaverRewardsFactory } from '@/services/design/SaverRewards';
import Features from '@/components/form/design/Features.vue';

@Component({
	components: {
		Features
	}
})
export default class SaverRewards extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;

	created() {
		if (!this.designData.saverRewards) {
			this.designData.saverRewards = SaverRewardsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;
	}

	getFeatures(): Features {
		return this.$refs.features as Features & { $v: Validation };
	}

	touch() {
		this.getFeatures().touch();
	}

	reset() {
		this.getFeatures().reset();
	}

	isDirty(): boolean {
		return this.isMounted && this.getFeatures().isDirty() || false;
	}

	hasError(): boolean {
		return this.isMounted && this.getFeatures().hasError() || false;
	}
}
