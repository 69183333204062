



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Details } from '@/services/Product';
import { KReadOnly } from '@kasasa/fbase-components';

@Component({
	components: {
		KReadOnly
	}
})
export default class ProductDetails extends Vue {
	// NOTE: THIS CLASS/COMPONENT IS CALLED "ProductDetails" TO AVOID CONFLICTS WITH THE HTML <details> ELEMENT
	@Prop({default: false}) readonly details !: Details;
}
