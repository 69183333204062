export interface Features {
	autoQualifyCycles: 0 | 1 | null;
	freeChecking: boolean;
	freeBankATM: boolean;
	freeCard: boolean;
	freeCardName: string;
	freeOnlineBanking: boolean;
	freeOnlineBillPay: boolean;
}
export const FeaturesFactory = function(): Features {
	return {
		autoQualifyCycles: null,
		freeChecking: true,
		freeBankATM: true,
		freeCard: true,
		freeCardName: '',
		freeOnlineBanking: true,
		freeOnlineBillPay: true,
	} as Features;
};