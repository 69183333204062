












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Transactions as ITransactions } from '@/services/design/Transactions';

@Component({
	components: {}
})

export default class Transactions extends Vue {
	@Prop({}) readonly txn !: ITransactions;

	dollarFormat(x: number) {
		return '$' + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	get reqTrans(): string {
		return this.txn.reqTrans ? 'Yes' : 'No';
	}

	get reqTransType(): string {
		return this.txn.reqTransType?.charAt(0).toUpperCase() + 
		this.txn.reqTransType?.slice(1);
	}

	get reqTransNum(): string {
		return this.txn.reqTransNum ? this.txn.reqTransNum.toString() : '[Not Set]';
	}

	get reqTransMinAmount(): string {
		return this.dollarFormat(Number(this.txn.reqTransMinAmount)) || '[Not Set]';
	}

	get signatureBasedTransactions(): string {
		return this.txn.signatureBasedTransactions ? 'Yes' : 'No';
	}
}
