












































































import { AuthGroupManager, Alert, Dialog, NoticeClass, NoticeResponse, ExpandableFilter } from '@kasasa/fbase-components/lib';
import { AxiosResponse } from 'axios';
import { Component, Watch } from 'vue-property-decorator';
import { KCardHeaderBtn, KCardHeader, KCardFooter, KCardFooterBtn, KSpinner } from '@kasasa/fbase-components';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import Product from '@/services/Product';
import ProductHeader from '@/components/preview/ProductHeader.vue';
import ProductService from '@/services/ProductService.ts';
import ProductTabs from '@/components/preview/ProductTabs.vue';
import Vue from 'vue';

const auth = namespace('auth');

@Component({
	components: {
		ProductTabs,
		ProductHeader,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		KSpinner
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class ProductPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;
	canWrite = false;
	title = 'Product Details';
	productStatus = 'unloaded';

	productSvc = new ProductService(this.$store);
	product: Product = {} as Product;

	navigateToEdit() {
		this.$router.push({ name: 'EditProduct', params: this.$route.params });
	}

	created() {
		// to be used w/ k-card-header for the action buttons
		this.canWrite = this.authManager.canWrite('products');
	}

	@Watch('$route', { immediate: true, deep: true })
	loadData() {
		this.productStatus = 'unloaded';
		const filter = (new ExpandableFilter).expand('details').expand('designData');
		this.productSvc.find(this.$route.params.clientId, this.$route.params.id, filter)
			.then(resp => {
				this.product = resp.data.data;
				this.productStatus = 'loaded';
			})
			.catch((e) => {
				// no need to listen for 401, 403 and 500
				if (e.response.status === 404) {
					// throw an Alert notice?
					const notFound = new Alert(`Product "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
					this.$store.dispatch('notices/add', notFound);
					this.productStatus = '404';
				}
			});
	}

	get items(): Array<object> {
		return [
			{
				key: '0',
				text: 'Products',
				disabled: false,
				link: true,
				exact: true,
				to: { name: 'Products', params: { clientId: this.$route.params.clientId } },
			},
			{
				key: '1',
				text: this.title,
				disabled: true,
			}
		];
	}

	async deleteProduct() {
		const dialog = new Dialog(
			'Delete this product?',
			`Are you sure you want to delete product "${this.product.title}"? Caution: This can't be undone.`,
			'DELETE'
		);
		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const success = new Alert(`${this.product.title} is deleted. `, NoticeClass.SUCCESS);
		success.setTimeout(6000);
		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.productSvc.delete(this.$route.params.clientId, this.$route.params.id).then((deleteRes: AxiosResponse) => {
					if (deleteRes.status === 204) {
						this.$store.dispatch('notices/add', success);
						this.$router.push({name: 'Products', params: this.$route.params});
					} else {
						this.$store.dispatch('notices/add', new Alert(
							`An error occured while deleting "${this.product.title}`,
							NoticeClass.ERROR
						));
					}
				});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

	closeAction() {
		this.$router.push({name: 'Products', params: {clientId: this.$route.params.clientId}});
	}
}
