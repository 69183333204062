



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import ProductListFilterType from '@/components/ProductListFilter/ProductListFilterType.vue';
import ProductListFilterStatus from '@/components/ProductListFilter/ProductListFilterStatus.vue';

import { ProductListTableFilters } from '@/types/ProductListTableFilters';

@Component({
	components: {
		ProductListFilterType,
		ProductListFilterStatus
	}
})
export default class ProductListFilter extends Vue {
	@Prop({}) filters!: ProductListTableFilters;

	updateProductTypes(value: number[]) {
		this.filters.productTypes = value;
		this.$store.commit('SET_TABLE_FILTERS', this.filters);
	}

	updateEnabled(value: number) {
		this.filters.enabled = value;
		this.$store.commit('SET_TABLE_FILTERS', this.filters);
	}

	reset() {
		this.filters.productTypes = [];
		this.filters.enabled = 2;
		this.$store.commit('SET_TABLE_FILTERS', this.filters);
	}

}

