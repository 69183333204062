
/**
 * Take a value from a text-based input and convert to integer
 * Used for the sake of not confusing the poor PHP API model
 * Decimal inputs use string values
 * Customized behavior as opposed to ".number" directive
 * 
 * @param value Returned from v-model / change event of a text input
 * 		Note that this may be null, empty string, or a string full of alphabetic garbage
 * 
 * @returns The value to store in the JS model for validation
 * 		This will be null if empty, a Number if possible (but not NaN),
 * 		or if the value was not parseable ("asdf") the string
 * 
 * @example "" => null
 * @example "3" => 3
 * @example 0 => 0
 * @example null => null
 * @example 3 => 3
 * @example "3.5" => 3.5 (see note)
 */
export default function TextToNumber(value: string | number | null): string | number | null {
	if (!value) {
		return (value === 0 ? 0 : null);
	}

	if (typeof value === 'number') {
		return value; // NaN covered above
	}

	// numeric conversion which only converts "valid" numerics
	// - note that parseInt & parseFloat will parse "10a" as "10"
	// - literal "+" cast will convert "10a" to "NaN"
	// - no characters (commas, etc) are stripped since vuelidate will fail them anyway
	const numeric = +(value);
	if (isNaN(numeric)) {
		return value;
	}

	// let vuelidate handle the case where we return a valid float
	// possible todo - handle rounding
	return numeric;
}

