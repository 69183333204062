























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { BatchProduct } from '@/services/Product.ts';
import { Validation } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { ProductTypeList } from '@/services/ProductType';

import BatchAddOne from '@/components/form/BatchAddOne.vue';
import BatchAddBulk from '@/components/form/BatchAddBulk.vue';


type ValidationItem = Validation & {
	[key: string]: unknown;
	name: Validation;
	fkProductType: Validation;
	fkClient: unknown;
};

type keyString = string & keyof ValidationItem & keyof BatchProduct;

@Component({
	components: {
		BatchAddOne,
		BatchAddBulk,
	}
})
export default class BatchCreate extends Vue {
	@State('batch') batch!: Array<BatchProduct>;

	readonly defaultBatchSize = 2;

	get fkClient(): number {
		return +this.$route.params.clientId;
	}

	get productTypeOptions(): Array<{ value: number; text: string }> {
		return ProductTypeList
			.filter(productType => productType.displayHeader)
			.sort((a, b) => (a.name > b.name) ? 1 : -1)
			.map((productType: { name: string; id: number }) => {
				return {
					text: productType.name,
					value: productType.id,
				};
			});
	}

	activeIndex: number | null = null;
	hoverIndex: number | null = null;

	private activateIndex(index: number): void {
		this.activeIndex = index;
	}

	private deactivateIndex(index: number): void {
		if (this.activeIndex === index) {
			this.activeIndex = null;
		}
	}

	private getValidationAt(index: number): ValidationItem | null {
		return (this.$v?.batch?.$each?.[index] as ValidationItem) || null;
	}

	handleFocus(index: number): void {
		// look at last known active index - touch everything there
		if (this.activeIndex !== null && this.activeIndex !== index) {
			this.getValidationAt(index)?.$touch();
		}
		// touch / reset focused input?
		this.activateIndex(index);
	}

	handleBlur(index: number, key: keyString): void {
		// look at blurred input only - for same-card error messaging,
		// and cases when someone tabs through without changing anything
		(this.getValidationAt(index)?.[key] as Validation)?.$touch();
		this.deactivateIndex(index);
	}

	handleChange(index: number, key: keyString): void {
		// any change causes error validation to trigger on the current input
		(this.getValidationAt(index)?.[key] as Validation)?.$touch();
	}

	private itemKeyHasError(index: number, key: keyString): boolean {
		const validation = this.getValidationAt(index)?.[key] as Validation;
		if (validation && validation.$dirty && validation.$invalid) {
			return true;
		}
		return false;
	}

	created() {
		if (!this.batch || !this.batch.length) {
			this.addBulk(this.defaultBatchSize);
		}
	}

	validations(): RuleDecl {
		return {
			batch: {
				$each: {
					name: { required },
					fkProductType: { required, minValue: minValue(2) },
				}
			}
		} as RuleDecl;
	}

	private addOne(): void {
		this.batch.push({
			name: '',
			fkProductType: 6,
			fkClient: this.fkClient,
			enabled: 1,
			title: null,
		} as BatchProduct);
	}

	addBulk(n: number): void {
		if (n < 0) {
			// console.warn('Cannot add negative amount of products in bulk', n);
			return;
		}
		for (let i = 0; i < n; i++) {
			this.addOne();
		}
	}

	remove(index: number) {
		if (index < 0) {
			// console.warn('Cannot remove index which does not exist', index);
			return;
		}
		this.batch.splice(index, 1);
	}

	hasError(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void {
		this.$v?.$touch();
	}
}

