
export interface Base {
	isKasasa: boolean;
	externalAppURL: string;
	insuredBy: 'none' | 'fdic' | 'ncua' | 'asi' | null;
	initialDepositMin: string | number | null; // int(11)
	initialDepositMax: string | number | null; // int(11)
	initialCardDepositMin: string | number | null; // int(11)
	initialCardDepositMax: string | number | null; // int(11)

	// unimplemented
	anderaProductCode: string | null;
	extendProductType: number | null; 
	initialIavDepositMax: number | null; // int(11)
	isSyndicatedInmo: boolean;
	kasasaDetailPageRedirectUrl: string | null;
	kasasaOpenPageRedirectUrl: string | null;
	prettyUrl: string | null;
	useExternalApp: boolean;
}

export const BaseFactory = function(): Base {
	// by default, a product is Kasasa and is insured by FDIC
	return {
		isKasasa: false,
		externalAppURL: '',
		insuredBy: 'fdic',
		initialDepositMin: null,
		initialDepositMax: null,
		initialCardDepositMin: null,
		initialCardDepositMax: null,
		// unimplemented
		anderaProductCode: null,
		extendProductType: null,
		initialIavDepositMax: null,
		isSyndicatedInmo: false,
		kasasaDetailPageRedirectUrl: null,
		kasasaOpenPageRedirectUrl: null,
		prettyUrl: null,
		useExternalApp: false,
	} as Base;
};
