




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import DesignData from '@/services/DesignData';
import { InControlDetailsFactory } from '@/services/design/InControlDetails';

import InControlDetails from '@/components/form/design/InControlDetails.vue';
import Transactions from '@/components/form/design/Transactions.vue';
import EStatement from '@/components/form/design/EStatement.vue';
import AtmRefund from '@/components/form/design/AtmRefund.vue';

type ValidatedComponent = Vue & {
	hasError: () => boolean;
	isDirty: () => boolean;
	reset: () => void;
	touch: () => void;
};

@Component({
	components: {
		InControlDetails,
		Transactions,
		EStatement,
		AtmRefund
	}
})

export default class InControlChecking extends Vue {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	inControlDetails: ( InControlDetails ) | null = null;
	transactions: ( Transactions ) | null = null;
	eStatement: ( EStatement ) | null = null;
	atmRefund: ( AtmRefund ) | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.inControlDetailsRef = this.$refs.inControlDetails as InControlDetails & ValidatedComponent;
			this.transactionsRef = this.$refs.transactions as Transactions & ValidatedComponent;
			this.eStatementRef = this.$refs.eStatement as EStatement & ValidatedComponent;
			this.atmRefundRef = this.$refs.atmRefund as AtmRefund & ValidatedComponent;

			this.isMounted = true;
		});
	}

	created() {
		if (!this.designData.inControlDetails) {
			this.designData.inControlDetails = InControlDetailsFactory();
		}
		this.setDesignData(this.designData);
	}

	inControlDetailsRef: InControlDetails & ValidatedComponent | null = null;
	transactionsRef: Transactions & ValidatedComponent | null = null;
	eStatementRef: EStatement & ValidatedComponent | null = null;
	atmRefundRef: AtmRefund & ValidatedComponent | null = null;

	touch(): void {
		this.inControlDetailsRef?.touch();
		this.transactionsRef?.touch();
		this.eStatementRef?.touch();
		this.atmRefundRef?.touch();
	}

	reset(): void {
		this.inControlDetailsRef?.reset();
		this.transactionsRef?.reset();
		this.eStatementRef?.reset();
		this.atmRefundRef?.reset();
	}

	isDirty(): boolean {
		return this.isMounted && (
			this.inControlDetailsRef?.isDirty() ||
			this.transactionsRef?.isDirty() ||
			this.eStatementRef?.isDirty() ||
			this.atmRefundRef?.isDirty()
		) || false;
	}

	hasError(): boolean {
		return this.isMounted && (
			this.inControlDetailsRef?.hasError() ||
			this.transactionsRef?.hasError() ||
			this.eStatementRef?.hasError() ||
			this.atmRefundRef?.hasError()
		) || false;
	}
}


