export interface OnlineBanking {
	reqOnlineBanking: boolean;
	reqOnlineBankingNum: number;
}
export const OnlineBankingFactory = function(): OnlineBanking {
	return {
		reqOnlineBanking: true,
		reqOnlineBankingNum: 0,
	} as OnlineBanking;
};
