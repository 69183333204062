














import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import Generic from '@/components/form/productType/Generic.vue';
import Cashback from '@/components/form/productType/Cashback.vue';
import ChoiceChecking from '@/components/form/productType/ChoiceChecking.vue';
import InControlChecking from '@/components/form/productType/InControlChecking.vue';
import RewardChecking from '@/components/form/productType/RewardChecking.vue';
import Saver from '@/components/form/productType/Saver.vue';
import Tunes from '@/components/form/productType/Tunes.vue';

import DesignBase from '@/components/form/design/DesignBase.vue';

import Product from '@/services/Product.ts';

type ComponentMap = {
	[key: number]: string;
};

type ValidatedDesignSection = Vue & {
	touch: () => void;
	reset: () => void;
	isDirty: () => boolean;
	hasError: () => boolean;
};

@Component({
	components: {
		Generic,
		Cashback,
		ChoiceChecking,
		InControlChecking,
		RewardChecking,
		Saver,
		Tunes,
		DesignBase,
	},
})
export default class ProductDesign extends Vue {
	@State('product') product!: Product;

	@Watch('product.designData', { deep: true })
	watchDesignData() {
		this.$forceUpdate();
	}

	@Watch('product.fkProductType')
	watchProductType() {
		this.reset();
		this.$nextTick(() => {
			// reset ref reference (say that five times fast)
			this.productType = this.$refs.productType as ValidatedDesignSection & ProductDesign;
			this.touch();
		});
	}

	isMounted = false;

	mounted(): void {
		this.$nextTick(() => {
			this.productType = this.$refs.productType as ValidatedDesignSection & ProductDesign;
			this.designBase = this.$refs.designBase as ValidatedDesignSection & DesignBase;
		});
		this.isMounted = true;
	}

	productType: ( ValidatedDesignSection & ProductDesign ) | null = null;
	designBase: ( ValidatedDesignSection & DesignBase ) | null = null;

	touch(): void {
		this.productType?.touch();
		this.designBase?.touch();
	}

	reset(): void {
		this.productType?.reset();
		this.designBase?.reset();
	}

	isDirty(): boolean {
		if (!this.isMounted) {
			return false;
		}
		return this.productType?.isDirty() || this.designBase?.isDirty() || false;
	}

	hasError(): boolean {
		if (!this.isMounted) {
			return false;
		}
		return this.productType?.hasError() || this.designBase?.hasError() || false;
	}

	static readonly productTypeComponentMap: ComponentMap = {
		1: 'Generic',
		2: 'Generic',
		3: 'Generic',
		4: 'Generic',
		5: 'Generic',
		6: 'Generic',
		7: 'Generic',
		8: 'RewardChecking',
		9: 'Saver',
		10: 'Tunes',
		11: 'Generic',
		12: 'ChoiceChecking',
		13: 'Cashback',
		15: 'InControlChecking',
	};

	get productTypeComponent(): string {
		const type = this.product.designData.type;
		if (!type || !(type in ProductDesign.productTypeComponentMap)) {
			return 'Generic';
		}
		return ProductDesign.productTypeComponentMap[type];
	}
}
