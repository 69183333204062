








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { KReadOnly } from "@kasasa/fbase-components";

@Component({
	components: {
		KReadOnly
	}
})

export default class SaverDetails extends Vue {
	@Prop({}) readonly productName!: string;
	@Prop({}) readonly saverSweep!: boolean;

	staticText = 'This is the product of which a person must meet the requirements in order to qualify for the Saver Reward.';


	isYesNo(value: boolean): string {
		return value === true ? 'Yes' : 'No';
	}

}
