export interface Transactions {
	reqTrans: boolean;
	reqTransType: string;
	reqTransNum: number;
	reqTransMinAmount: number;
	signatureBasedTransactions: boolean;
}

export const TransactionsFactory = function(): Transactions {
	return {
		reqTrans: true,
		reqTransType: 'check',
		reqTransNum: 0,
		reqTransMinAmount: 0,
		signatureBasedTransactions: true,
	} as Transactions;
};