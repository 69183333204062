





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import Product from '@/services/Product';
import { getProductTypeName } from '@/services/ProductType';

@Component({
	components: {
		KReadOnly,
	},
}) 
export default class ProductHeaderRO extends Vue {
	@Prop({ default: false }) readonly product!: Product;

	get prodActive() {
		return this.product.enabled ? 'Active' : 'Inactive';
	}

	getProductTypeName(fkProductType: number | null): string {
		return getProductTypeName(fkProductType);
	}
}
