















import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import DesignData from '@/services/DesignData';
import { CheckingRewardsFactory } from '@/services/design/CheckingRewards';
// import { Validation } from 'vuelidate';

import Features from '@/components/form/design/Features.vue';
import AtmRefund from '@/components/form/design/AtmRefund.vue';

@Component({
	components: {
		Features,
		AtmRefund,
	}
})
export default class CheckingRewards extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	created() {
		if (!this.designData.checkingRewards) {
			this.designData.checkingRewards = CheckingRewardsFactory();
		}
		this.setDesignData(this.designData);
	}

	features: Features | null = null;
	atmRefund: AtmRefund | null = null;

	isMounted = false;

	mounted() {
		this.$nextTick(() => {
			this.features = this.$refs.features as Features;
			this.atmRefund = this.$refs.atmRefund as AtmRefund;
			this.isMounted = true;
		});
	}

	touch() {
		this.features?.touch();
		this.atmRefund?.touch();
	}

	reset() {
		this.features?.reset();
		this.atmRefund?.reset();
	}

	
	isDirty(): boolean {
		return this.isMounted && (
			this.features?.isDirty() ||
			this.atmRefund?.isDirty()
		) || false;
	}

	hasError(): boolean {
		return this.isMounted && (
			this.features?.hasError() ||
			this.atmRefund?.hasError()
		) || false;
	}
}
