















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { KReadOnly } from "@kasasa/fbase-components";
import { AtmRefund as AtmRefundInterface } from "@/services/design/AtmRefund";
import { CurrencyFilter } from "@/filters/Currency";

@Component({
	components: {
		KReadOnly
	}
})
export default class AtmRefund extends Vue {
	@Prop({}) readonly atmRefund!: AtmRefundInterface;

	isYesNo(value: boolean): string {
		return value === true ? 'Yes' : 'No';
	}

	capitalizeFirstLetter(value: string): string {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	formatCurrency(value: string): string {
		return CurrencyFilter(value);
	}
}
