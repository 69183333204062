const CurrencyValidator = (value: string | null) => {
	const currencyRe = /^\$?(0|[1-9][0-9]*)?(\.\d{2})?$/;
	if (!value || +value == 0) {
		return true;
	}
	if (+value < 1) {
		return false;
	}
	return currencyRe.test(value); 
};

const WholeDollarValidator = (value: string | null) => {
	const wholeDollarRe = /^\$?[1-9][0-9]*$/;
	if (!value || +value == 0) {
		return true;
	}
	if (+value < 1) {
		return false;
	}
	return wholeDollarRe.test(value); 
};

export {
	CurrencyValidator,
	WholeDollarValidator
};
