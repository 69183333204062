























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import DesignData from '@/services/DesignData';
import { InControlDetails as IInControlDetails } from '@/services/design/InControlDetails';
import { getInControlType } from '@/services/design/InControlDetails';
import Transactions from '@/components/preview/design/Transactions.vue';
import EStatement from '@/components/preview/design/EStatement.vue';
import AtmRefund from '@/components/preview/design/AtmRefund.vue';
import { CurrencyFilter } from "@/filters/Currency";

@Component({
	components: {
		KReadOnly,
		Transactions,
		EStatement,
		AtmRefund
	}
})

export default class InControlDetails extends Vue {
	@Prop({default: false}) readonly data !: DesignData & { inControlDetails: IInControlDetails };

	formatType(value: string) {
		return getInControlType(value);
	}

	formatCurrency(value: string) {
		return CurrencyFilter(value);
	}
}

