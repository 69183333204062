import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import ListProductsPage from '@/pages/ListProductsPage.vue';
import ProductPage from '@/pages/ProductPage.vue';
import ProductFormPage from '@/pages/ProductFormPage.vue';
import BatchCreatePage from '@/pages/BatchCreatePage.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomePage,
		meta: {
			addProduct: false
		},
	},
	{
		path: '/:clientId',
		name: 'Products',
		component: ListProductsPage,
		meta: {
			addProduct: true
		},
	},
	{
		path: '/:clientId/product/add',
		name: 'AddProduct',
		component: ProductFormPage,
		meta: {
			mode: 'add',
			addProduct: false
		}
	},
	{
		path: '/:clientId/product/batch-add',
		name: 'BatchCreate',
		component: BatchCreatePage,
		meta: {
			mode: 'add',
			addProduct: false
		}
	},
	{
		path: '/:clientId/product/:id',
		name: 'ViewProduct',
		component: ProductPage,
		meta: {
			addProduct: false
		}
	},
	{
		path: '/:clientId/product/:id/edit',
		name: 'EditProduct',
		component: ProductFormPage,
		meta: {
			mode: 'edit',
			addProduct: false
		}
	},
	{
		path: '*',
		redirect: { name: 'Home' }
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
