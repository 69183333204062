


























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
export default class BatchAddOne extends Vue {
	// notta
}

