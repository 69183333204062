






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import DesignData from '@/services/DesignData';

@Component({
	components: { /* none */ }
})
export default class Cashback extends Vue {
	@Prop({}) designData!: DesignData;

	touch(): void { /* no sub-components */ }
	reset(): void { /* no sub-components */ }

	isDirty(): boolean {
		return false;
	}

	hasError(): boolean {
		return false;
	}
}


