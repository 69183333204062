
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Conditions as ConditionsData } from '@/services/design/Conditions';
import { BoolYesNoFilter } from "@/filters/BoolYesNo";

@Component({})

export default class Conditions extends Vue {
	@Prop({default: false}) readonly conditionsData !: ConditionsData;
	
	formatYesNo(value: boolean) {
		return BoolYesNoFilter(value);
	}
}

