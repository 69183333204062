import DesignData, { DesignDataFactory } from './DesignData';


interface RequiredProductFields {
	fkClient: number | null;
	fkProductType: number;
	name: string;
}
interface OptionalProductFields {
	id: number | null;
	enabled: number; // should this be boolean?
	title: string | null;
	general: General;
	details: Array<Details>;
	designData: DesignData;
}

interface Product extends RequiredProductFields, OptionalProductFields {}
interface BatchProduct extends RequiredProductFields, Partial<OptionalProductFields> {}

interface General {
	description: string;
	alternateDescription: string;
	disclaimer: string;
}

interface Details {
	[key: string]: string;
	name: string;
	content: string;
}

const defaultProduct = {
	id: null,
	enabled: 1, // default "Active"
	fkClient: null,
	fkProductType: 6, // default "Other"
	name: "",
	title: "",
	general: {} as General,
	details: [],
	designData: {} as DesignData
}  as Product;

const ProductFactory = function(): Product {
	const product = Object.assign({}, defaultProduct);
	product.details = [] as Details[];

	product.general = {
		description: '',
		alternateDescription: '',
		disclaimer: '',
	} as General;

	product.designData = DesignDataFactory(product.fkProductType);

	return product;
};


export default Product;
export {
	ProductFactory,
	General,
	Details,
	Product,
	BatchProduct,
};
