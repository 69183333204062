

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { KReadOnly } from "@kasasa/fbase-components";
import { TunesDetails as Details } from "@/services/design/TunesDetails";
import { CurrencyFilter } from "@/filters/Currency";

@Component({
	components: {
		KReadOnly
	}
})
export default class TunesDetails extends Vue {
	@Prop({}) readonly tunesDetails!: Details;

	isYesNo(value: boolean): string {
		return value === true ? 'Yes' : 'No';
	}

	get perMonth(): string {
		if (this.tunesDetails.tunesDollars) {
			return CurrencyFilter(this.tunesDetails.downloadsPerMonth);
		} else {
			return parseInt(this.tunesDetails.downloadsPerMonth).toString();
		}
	}

	get signupBonus(): string {
		if (this.tunesDetails.tunesDollars) {
			return CurrencyFilter(this.tunesDetails.signUpBonusDownloads);
		} else {
			return parseInt(this.tunesDetails.signUpBonusDownloads).toString();
		}
	}
}
