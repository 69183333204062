
































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { KCardHeader, KSpinner, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import ProductHeader from '@/components/form/ProductHeader.vue';
import ProductTabs from '@/components/form/ProductTabsForm.vue';
import ProductService from '@/services/ProductService';
import ProductDesign from '@/components/form/ProductDesign.vue';
import { Product, Details } from '@/services/Product';
import { AuthGroupManager, Dialog, Alert, NoticeClass, NoticeResponse, ExpandableFilter } from '@kasasa/fbase-components/lib';
import { Validation } from 'vuelidate';
import { State, Action, namespace } from 'vuex-class';
import { mapState } from 'vuex';
import uniq from 'lodash/uniq';
import { Route, RawLocation } from 'vue-router';

// replace once we have from fbase-components
interface Error {
	code: string;
	message: string;
	field: string | null;
	errors: Error[];
}

const auth = namespace('auth');

@Component({
	components: {
		ProductHeader,
		ProductTabs,
		ProductDesign,
		KCardHeader,
		KCardFooter,
		KCardFooterBtn,
		KSpinner
	},
	computed: {
		...mapState(['auth'])
	}
})

export default class ProductFormPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;
	@State('product') product!: Product;
	@Action('setNewProduct') setNewProduct!: Function;
	@Action('setProduct') setProduct!: Function;
	@Action('inflateDesignData') inflateDesignData!: Function;

	productStatus: 'loaded' | 'loading' | 'unloaded' = 'unloaded';
	productSvc = new ProductService(this.$store);
	showDelete = false;
	decimalRegex = new RegExp('[.].+');

	@Watch('$route', { immediate: true, deep: true })
	loadData() {
		if (this.$route.meta.mode === 'edit') {
			this.productStatus = 'loading';
			const filter = (new ExpandableFilter).expand('details').expand('designData');
			this.productSvc.find(this.$route.params.clientId, this.$route.params.id, filter)
				.then(resp => {
					if (resp.data.data.details.length === 0) {
						resp.data.data.details = [{ name: '', content: '' }];
					}
					this.setProduct(resp.data.data);
					this.inflateDesignData(resp.data.data.designData);
					this.productStatus = 'loaded';
					this.showDelete = true;
				})
				.catch(e => {
					// no need to listen for 401, 403 and 500
					if (e.response.status === 404) {
						// throw an Alert notice?
						const notFound = new Alert(`Product "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
						this.$store.dispatch('notices/add', notFound);
					}
				});
		} else if(this.$route.meta.mode === 'add') {
			this.resetProduct();
			this.productStatus = 'loaded';
			this.showDelete = false;
		}
	}

	get isUserExternal(): boolean {
		return this.authManager.isExternal();
	}

	/**
	 * scrub cache to avoid old data showing in "intermediate" states
	 */
	resetProduct() {
		this.setNewProduct(parseInt(this.$route.params.clientId, 10));
	}

	get showDesignTab() {
		return this.authManager.isRoot() || this.authManager.isInternal();
	}

	get title() {
		return this.$route.meta.mode == 'add' ? 'Add New Product' : 'Edit';
	}

	get crumbs() {
		const crumbs = [];
		crumbs.push(
			{
				key: '0',
				text: 'Products',
				disabled: false,
				link: true,
				exact: true,
				to: { name: 'Products', params: { clientId: this.$route.params.clientId } }
			});
		if (this.$route.meta.mode == 'add') {
			crumbs.push({
				key:'1',
				text: 'Add New Product',
				disabled: true
			});
		} else {
			crumbs.push({
				key: '1',
				text: 'Product Details',
				disabled: false,
				link: true,
				exact: true,
				to: { name: 'ViewProduct', params: this.$route.params }
			});
			crumbs.push({
				key: '2',
				text: this.title,
				disabled: true
			});
		}
		return crumbs;
	}

	resetRefs() {
		const tabs: ProductTabs = (this.$refs.tabs as ProductTabs),
			header: Vue = (this.$refs.header as Vue & { $v: Validation});

		if (!tabs || !header) {
			return;
		}

		tabs.reset();
		header.$v.$reset();
	}

	checkForErrors() {
		const tabs: ProductTabs = (this.$refs.tabs as ProductTabs),
			header: Vue = (this.$refs.header as Vue & { $v: Validation});

		let errors = false;

		tabs.touch();
		header.$v.$touch();

		errors = tabs.hasErrors || header.$v.$error;

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	checkForDirty() {
		const tabs: ProductTabs = (this.$refs.tabs as ProductTabs),
			header: Vue = (this.$refs.header as Vue & { $v: Validation});

		return header.$v.$anyDirty || tabs.isDirty;
	}

	async beforeRouteLeave(to: Route, from: Route, next: Function) {
		if (this.checkForDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.resetProduct();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.resetProduct();
			next();
		}
	}

	cancelAction() {
		// beforeRouteLeave() will detect unsaved changes
		// go back to Preview if on edit else products
		const route = {params: this.$route.params} as RawLocation & { name: string};
		if (this.$route.meta.mode == 'edit') {
			route.name = 'ViewProduct';
		} else {
			route.name = 'Products';
		}
		this.$router.push(route);
	}

	saveAction() {
		if (this.checkForErrors()) {
			return;
		}

		const saved = new Alert(`${this.product.title} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);

		const p: Promise<void> = new Promise((resolve) => {
			if (this.$route.meta.mode == 'edit') {
				if (this.product.details.find(detail => detail.name === '' && detail.content === '')) {
					this.product.details = this.removeBlankDetails(this.product.details);
				}
				this.productSvc.update(this.$route.params.clientId, this.product)
					.then((resp) => {
						this.setProduct(resp.data.data);

						this.$store.dispatch('notices/add', saved);
						this.resetRefs();
						resolve();
					})
					.catch((err) => {
						this.manageErrors(err.response.data.error.errors);
						resolve();
					});
			} else {
				if (this.product.details.find(detail => detail.name === '' && detail.content === '')) {
					this.product.details = this.removeBlankDetails(this.product.details);
				}
				this.productSvc.create(this.$route.params.clientId, this.product)
					.then((resp) => {
						this.$store.dispatch('notices/add', saved);
						this.resetRefs();
						this.$router.push({name: 'ViewProduct', params: {
							clientId: this.$route.params.clientId,
							id: resp.data.data.id
						}});
						resolve(); // router push, but let's be nice
					})
					.catch((err) => {
						this.manageErrors(err.response.data.error.errors);
						resolve();
					});
			}
		});
		return p;
	}

	saveCloseAction() {
		if (this.checkForErrors()) {
			return;
		}

		const saved = new Alert(`${this.product.title} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);

		const p: Promise<void> = new Promise((resolve) => {
			if (this.$route.meta.mode == 'edit') {
				if (this.product.details.find(detail => detail.name === '' && detail.content === '')) {
					this.product.details = this.removeBlankDetails(this.product.details);
				}
				this.productSvc.update(this.$route.params.clientId, this.product)
					.then(() => {
						this.$store.dispatch('notices/add', saved);
						this.resetRefs();
						this.resetProduct();
						this.$router.push({name: 'Products', params: this.$route.params});
						resolve();
					})
					.catch((err) => {
						this.manageErrors(err.response.data.error.errors);
						resolve();
					});
			} else {
				if (this.product.details.find(detail => detail.name === '' && detail.content === '')) {
					this.product.details = this.removeBlankDetails(this.product.details);
				}
				this.productSvc.create(this.$route.params.clientId, this.product)
					.then(() => {
						this.$store.dispatch('notices/add', saved);
						this.resetRefs();
						this.resetProduct();
						this.$router.push({name: 'Products', params: this.$route.params});
						resolve();
					})
					.catch((err) => {
						this.manageErrors(err.response.data.error.errors);
						resolve();
					});
			}
		});
		return p;
	}

	manageErrors(errors: Error[]) {
		const fields: string[] = [];

		errors.forEach((e: Error) => {
			e.field && fields.push(e.field);
		});

		const broken = new Alert('We found some errors with the following fields: ' + uniq(fields).join(), NoticeClass.ERROR);
		this.$store.dispatch('notices/add', broken);
	}

	removeBlankDetails(details: Array<Details>): Array<Details> {
		const tempDetails = [] as Array<Details>;
		details.forEach(detail => {
			if (detail.name !== '' && detail.content !== '') {
				tempDetails.push(detail);
			}
		});
		return tempDetails;
	}
}
