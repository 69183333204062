












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { CheckingRewards as CheckingRewardsInterface } from "@/services/design/CheckingRewards";
import Features from "./Features.vue";
import AtmRefund from "./AtmRefund.vue";

@Component({
	components: {
		Features,
		AtmRefund,
	}
})
export default class CheckingRewards extends Vue {
	@Prop({}) readonly checkingRewards!: CheckingRewardsInterface;
}

