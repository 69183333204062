



































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { numeric, required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { Transactions as ITransactions } from '@/services/design/Transactions';
import { KRadioGroup, KText } from '@kasasa/fbase-components';
import { CurrencyValidator } from '@/components/form/validations/Currency';

type TransactionsValidation = Validation & {
	[key: string]: unknown;
	reqTrans: Validation;
	reqTransType: Validation;
	reqTransNum: Validation;
	reqTransMinAmount: Validation;
	signatureBasedTransactions: Validation;
};

@Component({
	components: {
		KRadioGroup, KText
	}
})
export default class Transactions extends Vue implements DesignSection {
	@Prop({}) transactions!: ITransactions;

	readonly reqTransItems = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];
	readonly reqTransTypeItems = [
		{ text: 'Check', value: 'check' },
		{ text: 'Debit', value: 'debit' },
	];
	readonly signatureBasedTransactionsItems = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];

	isMounted = false;
	
	mounted() {
		this.isMounted = true;
	}


	validations(): RuleDecl {
		return {
			transactions: {
				reqTrans: {},
				reqTransType: {},
				reqTransNum: {
					numeric, required
				},
				reqTransMinAmount: {
					CurrencyValidator, required
				},
				signatureBasedTransactions: {},
			}
		} as RuleDecl;
	}

	get transactionsValidation(): TransactionsValidation {
		return this.$v.transactions as TransactionsValidation;
	}

	touch() {
		this.transactionsValidation.$touch();
	}

	reset() {
		this.transactionsValidation.$reset();
	}

	isDirty(): boolean {
		return this.transactionsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.transactionsValidation.$anyError;
	}

	get reqTransNumErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.transactionsValidation.reqTransNum?.$invalid) {
			return 'Only whole numbers are allowed';
		}
		return null;
	}

	get reqTransMinAmountErrorMessage(): string | null {
		if (!this.isMounted) {
			return null;
		}
		if (this.transactionsValidation.reqTransMinAmount?.$invalid) {
			return 'Only dollar amounts are allowed';
		}
		return null;
	}
}
