























































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import DesignData from '@/services/DesignData';
import { SaverDetailsFactory } from '@/services/design/SaverDetails';
import ProductService from "@/services/ProductService";

type SaverDetailsValidation = Validation & {
	[key: string]: unknown;
	saverAssociatedProduct: Validation;
	saverSweep: Validation;
};

interface SaverAssociatedProducts {
	text: string;
	value: number | null;
}

@Component({
	components: {}
})
export default class SaverDetails extends Vue implements DesignSection {
	@Action('setDesignData') setDesignData!: Function;
	@Prop({}) designData!: DesignData;

	isMounted = false;
	isLoading = true;
	saverAssociatedProducts: SaverAssociatedProducts[] = [
		{
			text: 'None',
			value: 0
		}
	];

	validations(): RuleDecl {
		return {
			designData: {
				saverDetails: {
					saverAssociatedProduct: {},
					saverSweep: {},
				},
			}
		} as RuleDecl;
	}

	created() {
		if (!this.designData.saverDetails) {
			this.designData.saverDetails = SaverDetailsFactory();
		}
		this.setDesignData(this.designData);
	}

	mounted() {
		this.isMounted = true;

		const productService = new ProductService(this.$store);
		const params = {
			types: '8,13'
		};

		productService.findAll(this.$route.params.clientId, params)
			.then(resp => {
				this.saverAssociatedProducts = [
					...this.saverAssociatedProducts,
					...resp.data.data
						.map(product => ({
							text: product.enabled ? product.name : product.name + ' [Inactive]',
							value: product.id
						}))
						.sort(function(a, b) {
							return a.text.localeCompare(b.text);
						})
				];

				if(!this.saverAssociatedProducts.some(product => product.value == this.designData?.saverDetails?.saverAssociatedProduct)) {
					this.saverAssociatedProducts = [
						{
							text: '[Deleted]',
							value: this.designData?.saverDetails?.saverAssociatedProduct || null
						},
						...this.saverAssociatedProducts
					];
				}
			})
			.finally(() => {
				this.isLoading = false;
			});
	}

	get saverDetailsValidation(): SaverDetailsValidation {
		return this.$v?.designData?.saverDetails as unknown as SaverDetailsValidation;
	}

	touch() {
		this.saverDetailsValidation.$touch();
	}

	reset() {
		this.saverDetailsValidation.$reset();
	}

	isDirty(): boolean {
		return this.saverDetailsValidation.$anyDirty;
	}

	hasError(): boolean {
		return this.saverDetailsValidation.$anyError;
	}
}
