
































































































import Vue from 'vue';
import DesignSection from '@/components/form/design/DesignSection';

import { Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { AtmRefund as IAtmRefund } from '@/services/design/AtmRefund';
import { KRadioGroup, KText } from '@kasasa/fbase-components';

import { RuleDecl } from 'vue/types/options';
import { CurrencyValidator, WholeDollarValidator } from '@/components/form/validations/Currency';
import { required } from 'vuelidate/lib/validators';

type AtmRefundValidation = Validation & {
	[key: string]: unknown;
	atmRefundEnabled?: Validation;
	atmRefundScope?: Validation;
	atmRefundUnlimited?: Validation;
	atmRefundCycleCap: Validation;
	atmRefundItemCap: Validation;
	atmInstanceCap: Validation;
	atmReceiptDisclaimerEnabled?: Validation;
	atmRefundMod: Validation;
};

const YesNoFactory = () => {
	return [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];
};

@Component({
	components: {
		KRadioGroup, KText
	},
})
export default class AtmRefund extends Vue implements DesignSection {
	@Prop({}) atmRefund!: IAtmRefund;
	@Prop({}) type!: number;

	readonly atmRefundEnabledItems = YesNoFactory();
	readonly atmRefundUnlimitedItems = YesNoFactory();
	readonly atmReceiptDisclaimerEnabledItems = YesNoFactory();
	readonly atmRefundScopeItems = [
		{ text: 'Worldwide', value: 'global' },
		{ text: 'National', value: 'national' },
	];

	validations(): RuleDecl {
		return {
			atmRefund: {
				atmRefundEnabled: {},
				atmRefundScope: {},
				atmRefundUnlimited: {},
				atmRefundCycleCap: { WholeDollarValidator },
				atmRefundItemCap: { WholeDollarValidator },
				atmInstanceCap: { WholeDollarValidator },
				atmReceiptDisclaimerEnabled: {},
				atmRefundMod: { CurrencyValidator, required },
			},
		} as RuleDecl;
	}

	isMounted = false;

	mounted() {
		this.isMounted = true;
	}

	// hirt

	touch() {
		this.$v?.atmRefund?.$touch();
	}

	reset() {
		this.$v?.atmRefund?.$reset();
	}

	isDirty(): boolean {
		return this.isMounted && this.$v?.atmRefund?.$anyDirty || false;
	}

	hasError(): boolean {
		return this.isMounted && this.$v?.atmRefund?.$anyError || false;
	}

	// error messages

	readonly invalidDecimalDollar = 'Only dollar amounts are allowed';
	readonly invalidIntegerDollar = 'Only whole dollar amounts are allowed';

	get atmRefundCycleCapErrorMessage(): string | null {
		return (
			(this.isMounted &&
				(this.$v?.atmRefund?.atmRefundCycleCap?.$invalid
					? this.invalidIntegerDollar
					: null)) ||
			null
		);
	}

	get atmRefundItemCapErrorMessage(): string | null {
		return (
			(this.isMounted &&
				(this.$v?.atmRefund?.atmRefundItemCap?.$invalid
					? this.invalidIntegerDollar
					: null)) ||
			null
		);
	}

	get atmInstanceCapErrorMessage(): string | null {
		return (
			(this.isMounted &&
				(this.$v?.atmRefund?.atmInstanceCap?.$invalid
					? this.invalidIntegerDollar
					: null)) ||
			null
		);
	}

	get atmRefundModErrorMessage(): string | null {
		return (
			(this.isMounted &&
				(this.$v?.atmRefund?.atmRefundMod?.$invalid
					? this.invalidDecimalDollar
					: null)) ||
			null
		);
	}
}
