var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"px-0":"","py-0":""}},[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"py-0 text-right",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"fa-search","single-line":"","hide-details":""},model:{value:(_vm.tableFilters.search),callback:function ($$v) {_vm.$set(_vm.tableFilters, "search", $$v)},expression:"tableFilters.search"}}),_c('v-switch',{staticClass:"d-inline-block pb-4 label-left",attrs:{"label":"Filters","hide-details":true},on:{"change":_vm.handleFilterChange},model:{value:(_vm.tableFilters.useFilter),callback:function ($$v) {_vm.$set(_vm.tableFilters, "useFilter", $$v)},expression:"tableFilters.useFilter"}})],1)],1),_c('product-list-filter',{directives:[{name:"show",rawName:"v-show",value:(_vm.useFilter),expression:"useFilter"}],ref:"productListFilterRef",attrs:{"filters":_vm.tableFilters},on:{"update:filters":function($event){_vm.tableFilters=$event}}}),(!_vm.filteredList.length)?_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('div',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col',{staticClass:"text-px-18 text--secondary"},[_vm._v(" We weren't able to find that product. Please change your search values. ")])],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"svg-inline":"","width":"566","src":require("@/assets/no-products.svg")}})])],1)],1)])],1):_vm._e(),(_vm.filteredList.length)?_c('v-data-table',{attrs:{"options":_vm.tableOptions,"headers":_vm.headers,"items":_vm.filteredList,"footer-props":{
			'items-per-page': 10,
			'items-per-page-options': [10, 25, 50, 100, -1],
		}},on:{"click:row":function (row) { return _vm.$router.push({name: 'ViewProduct', params: { clientId: _vm.$route.params.clientId, id: row.id }}); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
		var item = ref.item;
return [_c('div',{staticClass:"overflow-text-container"},[_c('div',{staticClass:"overflow-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.searchHighlight(item.name))}})])])]}},{key:"item.enabled",fn:function(ref){
		var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.enabled ? 'primary' : '#E0E0E0'}},[_vm._v(" "+_vm._s(item.enabled === 1 ? "Active" : "Inactive")+" ")])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }