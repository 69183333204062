
import { Transactions, TransactionsFactory } from '@/services/design/Transactions';
import { OnlineBanking, OnlineBankingFactory } from '@/services/design/OnlineBanking';
import { EStatement, EStatementFactory } from '@/services/design/EStatement';
import { AutomaticTransfers, AutomaticTransfersFactory } from '@/services/design/AutomaticTransfers';

export interface Requirements {
	transactions: Transactions;
	onlineBanking: OnlineBanking;
	eStatement: EStatement;
	automaticTransfers: AutomaticTransfers;
	others: string[];
}

export const RequirementsFactory = function(): Requirements {
	return {
		transactions: TransactionsFactory(),
		onlineBanking: OnlineBankingFactory(),
		eStatement: EStatementFactory(),
		automaticTransfers: AutomaticTransfersFactory(),
		others: [],
	} as Requirements;
};