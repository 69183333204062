

/**
 * Database form; easiest to use with <select> items
 */
const ProductTypeList = [
	{id: 2, name: "Savings", displayHeader: 1, isDefault: 0},
	{id: 3, name: "Loans", displayHeader: 1, isDefault: 0},
	{id: 4, name: "Investments", displayHeader: 1, isDefault: 0},
	{id: 5, name: "Lending", displayHeader: 1, isDefault: 0},
	{id: 6, name: "Other", displayHeader: 1, isDefault: 0},
	{id: 7, name: "Checking", displayHeader: 1, isDefault: 0},
	{id: 8, name: "Rewards Checking/Cash", displayHeader: 1, isDefault: 0},
	{id: 9, name: "Saver", displayHeader: 1, isDefault: 0},
	{id: 10, name: "Tunes", displayHeader: 1, isDefault: 0},
	{id: 12, name: "Choice Checking", displayHeader: 1, isDefault: 0},
	{id: 13, name: "Cashback", displayHeader: 1, isDefault: 0},
	{id: 15, name: "InControl Checking", displayHeader: 1, isDefault: 0},
];

/**
 * Enumeration form, easiest for checking validity
 */
enum ProductTypesEnum {
	Savings = 2,
	Loans = 3,
	Investments = 4,
	Lending = 5,
	Other = 6,
	Checking = 7,
	RewardChecking = 8,
	Saver = 9,
	Tunes = 10,
	ChoiceChecking = 12,
	Cashback = 13,
	InControlChecking = 15
}

interface SimpleMap {
	[key: number]: string;
}

/**
 * Map form, easiest for quickly getting a string from an ID
 */
const ProductTypeMap: SimpleMap = {
	2: 'Savings',
	3: 'Loans',
	4: 'Investments',
	5: 'Lending',
	6: 'Other',
	7: 'Checking',
	8: 'Rewards Checking/Cash',
	9: 'Saver',
	10: 'Tunes',
	12: 'Choice Checking',
	13: 'Cashback',
	15: 'InControl Checking',
};

/**
 * Wrapped functional form of product type map
 * 
 * @see ProductTypeMap
 * 
 * @param fkProductType numeric product ID, or null
 * @return canonical string for name (if set), not-set string if falsy, or empty string if invalid
 */
const getProductTypeName = (fkProductType: number | null): string => {
	if (!fkProductType) {
		return '[Not Set]';
	}

	if (fkProductType in ProductTypeMap) {
		return ProductTypeMap[fkProductType];
	}

	return '';
};

/**
 * @deprecated
 * @see ProductTypeList
 */
const productTypes = [
	'Savings',
	'Loans',
	'Investments',
	'Lending',
	'Other',
	'Checking',
	'Reward Checking',
	'Saver',
	'Tunes',
	'Choice Checking',
	'Cashback',
	'InControl Checking'
];


export {
	ProductTypesEnum,
	ProductTypeMap,
	ProductTypeList,
	getProductTypeName,
	productTypes,
	// getProductType,
};
