import Vue from 'vue';
import Vuex from 'vuex';

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';
import Product, { ProductFactory, BatchProduct } from '@/services/Product';
import DesignData, { DesignDataFactory } from '@/services/DesignData';
import { ProductListTableFilters, ProductListTableFiltersFactory } from '@/types/ProductListTableFilters';

Vue.use(Vuex);

function BatchFactory(): Array<BatchProduct> {
	return [];
}

export default new Vuex.Store({
	state: {
		product: ProductFactory(),
		batch: BatchFactory(),
		tableFilters: ProductListTableFiltersFactory()
	},
	mutations: {
		SET_PRODUCT(state, product: Product) {
			state.product = product;
		},
		SET_DESIGN_DATA(state, designData: DesignData) {
			(state.product as Product).designData = designData;
		},
		SET_BATCH(state, batch: Array<BatchProduct>) {
			state.batch = batch;
		},
		SET_TABLE_FILTERS(state, filters: ProductListTableFilters) {
			state.tableFilters = filters;
		}
	},
	actions: {
		setNewProduct(context, fkClient) {
			const product = ProductFactory();
			product.fkClient = fkClient;
			context.commit('SET_PRODUCT', product);
		},
		setProduct(context, product: Product) {
			context.commit('SET_PRODUCT', product);
		},
		setDesignData(context, designData: DesignData) {
			context.commit('SET_DESIGN_DATA', designData);
		},
		inflateDesignData(context, designData: DesignData) {
			const def = DesignDataFactory(null);
			context.commit('SET_DESIGN_DATA', {
				...def,
				...designData,
			} as DesignData);
		},
		setBatch(context, batch: Array<BatchProduct>) {
			context.commit('SET_BATCH', batch);
		}
	},
	getters: {
		tableFilters: state => {
			return state.tableFilters;
		}
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore
	},
	plugins: [pendoPlugin]
});
