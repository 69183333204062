export interface SaverDetails {
	[key: string]: unknown;
	saverAssociatedProduct: number;
	saverSweep: boolean;
}

export const SaverDetailsFactory = function(): SaverDetails {
	return {
		saverAssociatedProduct: 0,
		saverSweep: true,
	} as SaverDetails;
};