










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProductTypeList } from "@/services/ProductType";

@Component({})
export default class ProductListFilterType extends Vue {
		@Prop({ default: () => [] }) productTypes!: Array<number>;

		numberOfChips = 2;

		filterTypeOptions = ProductTypeList.sort((a, b) => {
			return (a.name.toLowerCase()) > (b.name.toLowerCase()) ? 1 : -1;
		}).map((a) => {
			return {
				text: a.name,
				value: a.id,
			};
		});

		set selected(value: Array<number>) {
			this.$emit('update', value);
		}

		get selected(): Array<number> {
			return this.productTypes;
		}

		doSelectAll() {
			if (this.productTypes.length) {
				this.$emit('update', []);
				return;
			}

			const types = this.filterTypeOptions.map((option) => {
				return option.value;
			});
			this.$emit('update', types);
		}

		get selectAllIcon() {
			// checked
			if (this.selected.length ===  this.filterTypeOptions.length) {
				return 'fas fa-check-square';
			}

			// partially checked
			if (this.selected.length > 0) {
				return 'fas fa-minus-square';
			}

			// unchecked
			return 'far fa-square';
		}

		get selectAllColor() {
			// checked
			if (this.selected.length === this.filterTypeOptions.length) {
				return 'indigo darken-4';
			}

			// partially checked
			if (this.selected.length > 0) {
				return 'indigo darken-4';
			}

			// unchecked
			return 'grey';
		}
}
