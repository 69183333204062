import { AtmRefund, AtmRefundFactory } from '@/services/design/AtmRefund';
import { Features, FeaturesFactory } from '@/services/design/Features';

export interface CheckingRewards {
	features: Features;
	atmRefund: AtmRefund;
}

export const CheckingRewardsFactory = function(): CheckingRewards {
	return {
		atmRefund: AtmRefundFactory(),
		features: FeaturesFactory(),
	} as CheckingRewards;
};
