import { EStatement, EStatementFactory } from '@/services/design/EStatement';
import { Transactions, TransactionsFactory } from '@/services/design/Transactions';
import { AtmRefund, AtmRefundFactory } from '@/services/design/AtmRefund';

export interface InControlDetails {
	[key: string]: unknown;
	monthlyFeeName: string;
	monthlyFeeAmount: number;
	extendProductType: string;
	eStatement: EStatement;
	transactions: Transactions;
	atmRefund: AtmRefund;
}

export const InControlDetailsFactory = function(): InControlDetails {
	return {
		monthlyFeeName: '',
		monthlyFeeAmount: 0,
		extendProductType: 'transactionsOrEstatement',
		eStatement: EStatementFactory(),
		transactions: TransactionsFactory(),
		atmRefund: AtmRefundFactory(),
	} as InControlDetails;
};

export const getInControlType = (type: string) => {
	switch(type) {
		case 'transactionsOrEstatement': {
			return 'Transactions or Estatement';
			break;
		}
		case 'transactionsAndEstatement': {
			return 'Transactions and Estatement';
			break;
		}
		default: {
			return '';
			break;
		}
	}
		
};