















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class DclCard extends Vue {
	@Prop({ default: () => false }) disabled!: boolean;
}

