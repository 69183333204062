export interface ReductionAmounts {
	// decimal(10,2) - db/api represents as numeric string
	reqEStatementReductionAmount: string | number | null;
	reqOnlineBankingReductionAmount: string | number | null;
	reqTransReductionAmount: string | number | null;
}
export const ReductionAmountsFactory = function(): ReductionAmounts {
	return {
		reqEStatementReductionAmount: null,
		reqOnlineBankingReductionAmount: null,
		reqTransReductionAmount: null,
	} as ReductionAmounts;
};